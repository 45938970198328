import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** An int representing an id field */
  IDInt: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type GQLCategorySortObject = {
  id: Scalars['IDInt'];
  products?: InputMaybe<Array<InputMaybe<Scalars['IDInt']>>>;
};

export type GQLCityType = GQLNode & {
  __typename?: 'CityType';
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryFlag: Scalars['String'];
  displayCity: Scalars['String'];
  displayState: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
};

export type GQLCompanyConfigMutation = {
  __typename?: 'CompanyConfigMutation';
  companyConfig?: Maybe<GQLCompanyConfigType>;
};

export type GQLCompanyConfigType = GQLNode & {
  __typename?: 'CompanyConfigType';
  aboutProductsUrl?: Maybe<Scalars['String']>;
  aboutRolesUrl?: Maybe<Scalars['String']>;
  adminTagCreation?: Maybe<Scalars['Boolean']>;
  areaEnabled: Scalars['Boolean'];
  backgroundColor?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<Scalars['String']>;
  companyLogoTitle?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  highlightColor?: Maybe<Scalars['String']>;
  homepageText?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  lightBackground: Scalars['Boolean'];
  lightNavbar: Scalars['Boolean'];
  mobileHomepageMessage: Scalars['String'];
  mobileHomepageText?: Maybe<Scalars['String']>;
  navbarLogo?: Maybe<Scalars['String']>;
  onboardingWizardEnabled?: Maybe<Scalars['Boolean']>;
  primaryButtonColor?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  productOrder?: Maybe<Scalars['JSONString']>;
};

export type GQLCompanyMutation = {
  __typename?: 'CompanyMutation';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLCompanyType = GQLNode & {
  __typename?: 'CompanyType';
  allowByIp?: Maybe<Scalars['Boolean']>;
  allowCidrs?: Maybe<Scalars['String']>;
  companyConfig?: Maybe<GQLCompanyConfigType>;
  companyId?: Maybe<Scalars['IDInt']>;
  employees: GQLEmployeeTypeConnection;
  groups: GQLGroupTypeConnection;
  /** The ID of the object */
  id: Scalars['ID'];
  mentionObjectUrl?: Maybe<Scalars['String']>;
  mentionTextName?: Maybe<Scalars['String']>;
  productCategories: GQLProductCategoryTypeConnection;
  productCategoryGroupings: GQLProductCategoryGroupingTypeConnection;
  products: GQLProductTypeConnection;
  roleCategories: GQLRoleCategoryTypeConnection;
  roles: GQLRoleTypeConnection;
  sessionTimeout?: Maybe<Scalars['Int']>;
};


export type GQLCompanyTypeEmployeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
};


export type GQLCompanyTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  subType?: InputMaybe<GQLGroupsGroupSubTypeChoices>;
  type?: InputMaybe<GQLGroupsGroupTypeChoices>;
};


export type GQLCompanyTypeProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLCompanyTypeProductCategoryGroupingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLCompanyTypeProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLCompanyTypeRoleCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLCompanyTypeRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GQLCreateProduct = {
  __typename?: 'CreateProduct';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
  product?: Maybe<GQLProductType>;
};

export type GQLCreateProductCategory = {
  __typename?: 'CreateProductCategory';
  category?: Maybe<GQLProductCategoryType>;
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLCreateProductCategoryGrouping = {
  __typename?: 'CreateProductCategoryGrouping';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  grouping?: Maybe<GQLProductCategoryGroupingType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLCreateRole = {
  __typename?: 'CreateRole';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
  role?: Maybe<GQLRoleType>;
};

export type GQLCreateRoleCategory = {
  __typename?: 'CreateRoleCategory';
  category?: Maybe<GQLRoleCategoryType>;
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLCustomSectionDataType = GQLNode & {
  __typename?: 'CustomSectionDataType';
  active: Scalars['Boolean'];
  customSection: GQLCustomSectionType;
  /** The ID of the object */
  id: Scalars['ID'];
  lastUpdated?: Maybe<Scalars['DateTime']>;
  mentions?: Maybe<Array<Maybe<GQLMentionType>>>;
  text: Scalars['String'];
};

export type GQLCustomSectionDataTypeConnection = {
  __typename?: 'CustomSectionDataTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLCustomSectionDataTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `CustomSectionDataType` and its cursor. */
export type GQLCustomSectionDataTypeEdge = {
  __typename?: 'CustomSectionDataTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLCustomSectionDataType>;
};

export type GQLCustomSectionType = GQLNode & {
  __typename?: 'CustomSectionType';
  activeByDefault: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  intendedFor: Scalars['String'];
  sectionType: Scalars['String'];
  sortOrder: Scalars['Int'];
  title: Scalars['String'];
  type: GQLOrgwikiCustomSectionTypeChoices;
  updateInterval: GQLOrgwikiCustomSectionUpdateIntervalChoices;
};

export type GQLDeleteProduct = {
  __typename?: 'DeleteProduct';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLDeleteProductCategory = {
  __typename?: 'DeleteProductCategory';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLDeleteProductCategoryGrouping = {
  __typename?: 'DeleteProductCategoryGrouping';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLDeleteRole = {
  __typename?: 'DeleteRole';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLDeleteRoleCategory = {
  __typename?: 'DeleteRoleCategory';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLEditProduct = {
  __typename?: 'EditProduct';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
  product?: Maybe<GQLProductType>;
};

export type GQLEditProductCategory = {
  __typename?: 'EditProductCategory';
  category?: Maybe<GQLProductCategoryType>;
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLEditProductCategoryGrouping = {
  __typename?: 'EditProductCategoryGrouping';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  grouping?: Maybe<GQLProductCategoryGroupingType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLEditRole = {
  __typename?: 'EditRole';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
  role?: Maybe<GQLRoleType>;
};

export type GQLEditRoleCategory = {
  __typename?: 'EditRoleCategory';
  category?: Maybe<GQLRoleCategoryType>;
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLEmployeeTagType = GQLNode & {
  __typename?: 'EmployeeTagType';
  employee: GQLEmployeeType;
  /** The ID of the object */
  id: Scalars['ID'];
  skillLevel: Scalars['Int'];
  tag: GQLTagType;
};

export type GQLEmployeeTagTypeConnection = {
  __typename?: 'EmployeeTagTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLEmployeeTagTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `EmployeeTagType` and its cursor. */
export type GQLEmployeeTagTypeEdge = {
  __typename?: 'EmployeeTagTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLEmployeeTagType>;
};

export type GQLEmployeeType = GQLNode & {
  __typename?: 'EmployeeType';
  additionalContactMethods: Scalars['String'];
  adminModeEnabled: Scalars['Boolean'];
  allowProfileEdits?: Maybe<Scalars['Boolean']>;
  balloons: Scalars['Boolean'];
  canCreateCompanyMentions: Scalars['Boolean'];
  canEditDesk?: Maybe<Scalars['Boolean']>;
  customSectionDatas: GQLCustomSectionDataTypeConnection;
  directReports: GQLEmployeeTypeConnection;
  dlistsCount?: Maybe<Scalars['Int']>;
  dottedLineManagers: GQLEmployeeTypeConnection;
  dottedLineReports: GQLEmployeeTypeConnection;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  groups: GQLGroupMemberTypeConnection;
  hideFlashcardScore?: Maybe<Scalars['Boolean']>;
  hideSensitiveContactInfo?: Maybe<Scalars['Boolean']>;
  homeCity?: Maybe<GQLCityType>;
  /** The ID of the object */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isBoardOfDirectors?: Maybe<Scalars['Boolean']>;
  isContractor: Scalars['Boolean'];
  isEarlyAdopter?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  lineage?: Maybe<Scalars['String']>;
  linkedinUrl: Scalars['String'];
  manager?: Maybe<GQLEmployeeType>;
  mattermostTeam?: Maybe<Scalars['String']>;
  mattermostUsername?: Maybe<Scalars['String']>;
  mentionObjectUrl?: Maybe<Scalars['String']>;
  mentionTextName?: Maybe<Scalars['String']>;
  mobilePhone: Scalars['String'];
  nickName: Scalars['String'];
  officePhone: Scalars['String'];
  preferredConferencing?: Maybe<GQLOrgwikiEmployeePreferredConferencingChoices>;
  preferredMessaging?: Maybe<GQLOrgwikiEmployeePreferredMessagingChoices>;
  priorCompanyName: Scalars['String'];
  priorStartDate?: Maybe<Scalars['Date']>;
  profileLastUpdated?: Maybe<Scalars['DateTime']>;
  profilePhoto?: Maybe<Scalars['String']>;
  profileStrength?: Maybe<Scalars['Int']>;
  pronunciationUrl?: Maybe<Scalars['String']>;
  provisionedManager?: Maybe<Scalars['String']>;
  publicId: Scalars['String'];
  role?: Maybe<GQLRoleType>;
  showBothCounts?: Maybe<Scalars['Boolean']>;
  showDirectReportsCount?: Maybe<Scalars['Boolean']>;
  skypeBusinessAddress: Scalars['String'];
  skypeName: Scalars['String'];
  slackName?: Maybe<Scalars['String']>;
  slackTeam?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  tags: GQLEmployeeTagTypeConnection;
  timezoneName: Scalars['String'];
  timezoneOffset: Scalars['Int'];
  title: Scalars['String'];
  transferDescription?: Maybe<Scalars['String']>;
  transferEntryPending: Scalars['Boolean'];
  transferLastUpdated?: Maybe<Scalars['DateTime']>;
  webex: Scalars['String'];
  zoomUrl?: Maybe<Scalars['String']>;
};


export type GQLEmployeeTypeCustomSectionDatasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLEmployeeTypeDirectReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
};


export type GQLEmployeeTypeDottedLineManagersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
};


export type GQLEmployeeTypeDottedLineReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
};


export type GQLEmployeeTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  group_Type?: InputMaybe<GQLGroupsGroupTypeChoices>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLEmployeeTypeTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GQLEmployeeTypeConnection = {
  __typename?: 'EmployeeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLEmployeeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `EmployeeType` and its cursor. */
export type GQLEmployeeTypeEdge = {
  __typename?: 'EmployeeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLEmployeeType>;
};

export type GQLError = {
  __typename?: 'Error';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type GQLGroupMemberType = GQLNode & {
  __typename?: 'GroupMemberType';
  group: GQLGroupType;
  /** The ID of the object */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  lastVisit?: Maybe<Scalars['DateTime']>;
  nestedGroup?: Maybe<GQLGroupType>;
  person?: Maybe<GQLEmployeeType>;
};

export type GQLGroupMemberTypeConnection = {
  __typename?: 'GroupMemberTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLGroupMemberTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `GroupMemberType` and its cursor. */
export type GQLGroupMemberTypeEdge = {
  __typename?: 'GroupMemberTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLGroupMemberType>;
};

export type GQLGroupType = GQLNode & {
  __typename?: 'GroupType';
  activeMemberCount?: Maybe<Scalars['Int']>;
  city?: Maybe<GQLCityType>;
  description: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  hideEmailMembers?: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  inviteOnly: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isFlat: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  isReadOnly: Scalars['Boolean'];
  isUtilityGroup?: Maybe<Scalars['Boolean']>;
  membersOnlySocial?: Maybe<Scalars['Boolean']>;
  mentionObjectUrl?: Maybe<Scalars['String']>;
  mentionTextName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  overviewEnabled: Scalars['Boolean'];
  overviewText: Scalars['String'];
  owners: GQLEmployeeTypeConnection;
  photo?: Maybe<Scalars['String']>;
  providerGroupType?: Maybe<Scalars['String']>;
  slugName: Scalars['String'];
  subType: GQLGroupsGroupSubTypeChoices;
  type: GQLGroupsGroupTypeChoices;
  whoCanPost?: Maybe<GQLGroupsGroupWhoCanPostChoices>;
};


export type GQLGroupTypeOwnersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
};

export type GQLGroupTypeConnection = {
  __typename?: 'GroupTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLGroupTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `GroupType` and its cursor. */
export type GQLGroupTypeEdge = {
  __typename?: 'GroupTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLGroupType>;
};

/** An enumeration. */
export enum GQLGroupsGroupSubTypeChoices {
  /** Auto Distribution List */
  AutoDlist = 'AUTO_DLIST',
  /** Custom Distribution List */
  CustomDlist = 'CUSTOM_DLIST',
  /** In Memoriam Group */
  InMemoriam = 'IN_MEMORIAM',
  /** Local Community */
  Local = 'LOCAL',
  /** Social Group */
  Social = 'SOCIAL'
}

/** An enumeration. */
export enum GQLGroupsGroupTypeChoices {
  /** Community Group */
  Community = 'COMMUNITY',
  /** Distribution List */
  DistributionList = 'DISTRIBUTION_LIST'
}

/** An enumeration. */
export enum GQLGroupsGroupWhoCanPostChoices {
  /** domain */
  A_1 = 'A_1',
  /** anyone */
  A_2 = 'A_2'
}

export type GQLMentionType = GQLNode & {
  __typename?: 'MentionType';
  /** The ID of the object */
  id: Scalars['ID'];
  mention?: Maybe<GQLMentionableType>;
  order?: Maybe<Scalars['Int']>;
};

export type GQLMentionableType = GQLCompanyType | GQLEmployeeType | GQLGroupType;

export type GQLMoveCategory = {
  __typename?: 'MoveCategory';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLMoveProduct = {
  __typename?: 'MoveProduct';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLMutation = {
  __typename?: 'Mutation';
  createProduct?: Maybe<GQLCreateProduct>;
  createProductCategory?: Maybe<GQLCreateProductCategory>;
  createProductCategoryGrouping?: Maybe<GQLCreateProductCategoryGrouping>;
  createRole?: Maybe<GQLCreateRole>;
  createRoleCategory?: Maybe<GQLCreateRoleCategory>;
  deleteProduct?: Maybe<GQLDeleteProduct>;
  deleteProductCategory?: Maybe<GQLDeleteProductCategory>;
  deleteProductCategoryGrouping?: Maybe<GQLDeleteProductCategoryGrouping>;
  deleteRole?: Maybe<GQLDeleteRole>;
  deleteRoleCategory?: Maybe<GQLDeleteRoleCategory>;
  editProduct?: Maybe<GQLEditProduct>;
  editProductCategory?: Maybe<GQLEditProductCategory>;
  editProductCategoryGrouping?: Maybe<GQLEditProductCategoryGrouping>;
  editRole?: Maybe<GQLEditRole>;
  editRoleCategory?: Maybe<GQLEditRoleCategory>;
  moveCategory?: Maybe<GQLMoveCategory>;
  moveProduct?: Maybe<GQLMoveProduct>;
  setCompany?: Maybe<GQLCompanyMutation>;
  setCompanyConfig?: Maybe<GQLCompanyConfigMutation>;
  setHomepageText?: Maybe<GQLSetHomepageText>;
  setProductOrder?: Maybe<GQLSetProductOrder>;
};


export type GQLMutationCreateProductArgs = {
  categoryId: Scalars['IDInt'];
  name: Scalars['String'];
};


export type GQLMutationCreateProductCategoryArgs = {
  groupingId?: InputMaybe<Scalars['IDInt']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type GQLMutationCreateProductCategoryGroupingArgs = {
  name: Scalars['String'];
};


export type GQLMutationCreateRoleArgs = {
  categoryId: Scalars['IDInt'];
  name: Scalars['String'];
};


export type GQLMutationCreateRoleCategoryArgs = {
  name: Scalars['String'];
};


export type GQLMutationDeleteProductArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['IDInt'];
};


export type GQLMutationDeleteProductCategoryArgs = {
  categoryId: Scalars['IDInt'];
};


export type GQLMutationDeleteProductCategoryGroupingArgs = {
  groupingId: Scalars['IDInt'];
};


export type GQLMutationDeleteRoleArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  roleId: Scalars['IDInt'];
};


export type GQLMutationDeleteRoleCategoryArgs = {
  categoryId: Scalars['IDInt'];
};


export type GQLMutationEditProductArgs = {
  name: Scalars['String'];
  productId: Scalars['IDInt'];
};


export type GQLMutationEditProductCategoryArgs = {
  categoryId: Scalars['IDInt'];
  hidden?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type GQLMutationEditProductCategoryGroupingArgs = {
  groupingId: Scalars['IDInt'];
  name: Scalars['String'];
};


export type GQLMutationEditRoleArgs = {
  categoryId?: InputMaybe<Scalars['IDInt']>;
  name?: InputMaybe<Scalars['String']>;
  roleId: Scalars['IDInt'];
};


export type GQLMutationEditRoleCategoryArgs = {
  categoryId: Scalars['IDInt'];
  name: Scalars['String'];
};


export type GQLMutationMoveCategoryArgs = {
  categoryId: Scalars['IDInt'];
  groupingId?: InputMaybe<Scalars['IDInt']>;
  newOrder: Array<InputMaybe<GQLSortObject>>;
};


export type GQLMutationMoveProductArgs = {
  categoryId: Scalars['IDInt'];
  newOrder: Array<InputMaybe<GQLSortObject>>;
  productId: Scalars['IDInt'];
};


export type GQLMutationSetCompanyArgs = {
  allowByIp?: InputMaybe<Scalars['Boolean']>;
  allowCidrs?: InputMaybe<Scalars['String']>;
  sessionTimeout?: InputMaybe<Scalars['Int']>;
};


export type GQLMutationSetCompanyConfigArgs = {
  adminTagCreation?: InputMaybe<Scalars['String']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
  companyLogoTitle?: InputMaybe<Scalars['String']>;
  companyLogoUrl?: InputMaybe<Scalars['String']>;
  highlightColor?: InputMaybe<Scalars['String']>;
  lightBackground?: InputMaybe<Scalars['Boolean']>;
  lightNavbar?: InputMaybe<Scalars['Boolean']>;
  navbarLogo?: InputMaybe<Scalars['Upload']>;
  onboardingWizardEnabled?: InputMaybe<Scalars['String']>;
  primaryButtonColor?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
};


export type GQLMutationSetHomepageTextArgs = {
  homepageText?: InputMaybe<Scalars['String']>;
  mobileHomepageMessage?: InputMaybe<Scalars['String']>;
  mobileHomepageText?: InputMaybe<Scalars['String']>;
};


export type GQLMutationSetProductOrderArgs = {
  newOrder: Array<InputMaybe<GQLSortObject>>;
};

/** An object with an ID */
export type GQLNode = {
  /** The ID of the object */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum GQLOrgwikiCustomSectionTypeChoices {
  /** About */
  A_1 = 'A_1',
  /** Responsibilities */
  A_2 = 'A_2',
  /** What am I doing this quarter */
  A_3 = 'A_3',
  /** Projects - Past and Present */
  A_4 = 'A_4',
  /** Key Accounts */
  A_5 = 'A_5',
  /** Custom */
  A_6 = 'A_6',
  /** In Memoriam */
  A_7 = 'A_7'
}

/** An enumeration. */
export enum GQLOrgwikiCustomSectionUpdateIntervalChoices {
  /** Monthly */
  Monthly = 'MONTHLY',
  /** Quarterly */
  Quarterly = 'QUARTERLY',
  /** Weekly */
  Weekly = 'WEEKLY',
  /** Yearly */
  Yearly = 'YEARLY'
}

/** An enumeration. */
export enum GQLOrgwikiEmployeePreferredConferencingChoices {
  A = 'A_',
  /** skype */
  Skype = 'SKYPE',
  /** skype_business */
  SkypeBusiness = 'SKYPE_BUSINESS',
  /** webex */
  Webex = 'WEBEX',
  /** zoom */
  Zoom = 'ZOOM'
}

/** An enumeration. */
export enum GQLOrgwikiEmployeePreferredMessagingChoices {
  A = 'A_',
  /** mattermost */
  Mattermost = 'MATTERMOST',
  /** slack */
  Slack = 'SLACK'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type GQLPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type GQLProductCategoryGroupingType = GQLNode & {
  __typename?: 'ProductCategoryGroupingType';
  categories: GQLProductCategoryTypeConnection;
  groupingId?: Maybe<Scalars['IDInt']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type GQLProductCategoryGroupingTypeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GQLProductCategoryGroupingTypeConnection = {
  __typename?: 'ProductCategoryGroupingTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLProductCategoryGroupingTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `ProductCategoryGroupingType` and its cursor. */
export type GQLProductCategoryGroupingTypeEdge = {
  __typename?: 'ProductCategoryGroupingTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLProductCategoryGroupingType>;
};

export type GQLProductCategoryType = GQLNode & {
  __typename?: 'ProductCategoryType';
  categoryId?: Maybe<Scalars['IDInt']>;
  grouping?: Maybe<GQLProductCategoryGroupingType>;
  hidden: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  products: GQLProductTypeConnection;
};


export type GQLProductCategoryTypeProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GQLProductCategoryTypeConnection = {
  __typename?: 'ProductCategoryTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLProductCategoryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `ProductCategoryType` and its cursor. */
export type GQLProductCategoryTypeEdge = {
  __typename?: 'ProductCategoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLProductCategoryType>;
};

export type GQLProductType = GQLNode & {
  __typename?: 'ProductType';
  category: GQLProductCategoryType;
  companyId?: Maybe<Scalars['IDInt']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  productId?: Maybe<Scalars['IDInt']>;
};

export type GQLProductTypeConnection = {
  __typename?: 'ProductTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLProductTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `ProductType` and its cursor. */
export type GQLProductTypeEdge = {
  __typename?: 'ProductTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLProductType>;
};

export type GQLQuery = {
  __typename?: 'Query';
  company?: Maybe<GQLCompanyType>;
  companyConfig?: Maybe<GQLCompanyConfigType>;
  employees?: Maybe<GQLEmployeeTypeConnection>;
  groups?: Maybe<GQLGroupTypeConnection>;
  memberships?: Maybe<GQLGroupMemberTypeConnection>;
  self?: Maybe<GQLEmployeeType>;
};


export type GQLQueryEmployeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
};


export type GQLQueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  subType?: InputMaybe<GQLGroupsGroupSubTypeChoices>;
  type?: InputMaybe<GQLGroupsGroupTypeChoices>;
};


export type GQLQueryMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  group_Type?: InputMaybe<GQLGroupsGroupTypeChoices>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GQLRoleCategoryType = GQLNode & {
  __typename?: 'RoleCategoryType';
  categoryId: Scalars['IDInt'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  roles: GQLRoleTypeConnection;
};


export type GQLRoleCategoryTypeRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GQLRoleCategoryTypeConnection = {
  __typename?: 'RoleCategoryTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLRoleCategoryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `RoleCategoryType` and its cursor. */
export type GQLRoleCategoryTypeEdge = {
  __typename?: 'RoleCategoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLRoleCategoryType>;
};

export type GQLRoleType = GQLNode & {
  __typename?: 'RoleType';
  category: GQLRoleCategoryType;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  roleId: Scalars['IDInt'];
};

export type GQLRoleTypeConnection = {
  __typename?: 'RoleTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLRoleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `RoleType` and its cursor. */
export type GQLRoleTypeEdge = {
  __typename?: 'RoleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLRoleType>;
};

export type GQLSetHomepageText = {
  __typename?: 'SetHomepageText';
  companyConfig?: Maybe<GQLCompanyConfigType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLSetProductOrder = {
  __typename?: 'SetProductOrder';
  company?: Maybe<GQLCompanyType>;
  error?: Maybe<GQLError>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GQLSkillCategoryType = GQLNode & {
  __typename?: 'SkillCategoryType';
  displayOrder: Scalars['Int'];
  externalId?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  tags: GQLTagTypeConnection;
};


export type GQLSkillCategoryTypeTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GQLSortObject = {
  categories?: InputMaybe<Array<InputMaybe<GQLCategorySortObject>>>;
  id: Scalars['IDInt'];
  products?: InputMaybe<Array<InputMaybe<Scalars['IDInt']>>>;
  type: GQLTopLevelType;
};

export type GQLTagType = GQLNode & {
  __typename?: 'TagType';
  category?: Maybe<GQLSkillCategoryType>;
  employees: GQLEmployeeTypeConnection;
  externalId?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  slugName?: Maybe<Scalars['String']>;
  synonymOf?: Maybe<GQLTagType>;
  synonyms: GQLTagTypeConnection;
};


export type GQLTagTypeEmployeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
};


export type GQLTagTypeSynonymsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GQLTagTypeConnection = {
  __typename?: 'TagTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GQLTagTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: GQLPageInfo;
};

/** A Relay edge containing a `TagType` and its cursor. */
export type GQLTagTypeEdge = {
  __typename?: 'TagTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GQLTagType>;
};

/** An enumeration. */
export enum GQLTopLevelType {
  Category = 'CATEGORY',
  Grouping = 'GROUPING'
}

export type GQLErrorPartsFragment = { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined };

export type GQLCreateRoleMutationVariables = Exact<{
  categoryId: Scalars['IDInt'];
  name: Scalars['String'];
}>;


export type GQLCreateRoleMutation = { __typename?: 'Mutation', createRole?: { __typename?: 'CreateRole', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, role?: { __typename?: 'RoleType', roleId: any, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, roleCategories: { __typename?: 'RoleCategoryTypeConnection', edges: Array<{ __typename?: 'RoleCategoryTypeEdge', node?: { __typename?: 'RoleCategoryType', id: string, categoryId: any, name: string, roles: { __typename?: 'RoleTypeConnection', edges: Array<{ __typename?: 'RoleTypeEdge', node?: { __typename?: 'RoleType', id: string, roleId: any, name: string } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLEditRoleMutationVariables = Exact<{
  roleId: Scalars['IDInt'];
  name?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['IDInt']>;
}>;


export type GQLEditRoleMutation = { __typename?: 'Mutation', editRole?: { __typename?: 'EditRole', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, role?: { __typename?: 'RoleType', roleId: any, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, roleCategories: { __typename?: 'RoleCategoryTypeConnection', edges: Array<{ __typename?: 'RoleCategoryTypeEdge', node?: { __typename?: 'RoleCategoryType', id: string, categoryId: any, name: string, roles: { __typename?: 'RoleTypeConnection', edges: Array<{ __typename?: 'RoleTypeEdge', node?: { __typename?: 'RoleType', id: string, roleId: any, name: string } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLDeleteRoleMutationVariables = Exact<{
  roleId: Scalars['IDInt'];
  force?: InputMaybe<Scalars['Boolean']>;
}>;


export type GQLDeleteRoleMutation = { __typename?: 'Mutation', deleteRole?: { __typename?: 'DeleteRole', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, company?: { __typename?: 'CompanyType', id: string, roleCategories: { __typename?: 'RoleCategoryTypeConnection', edges: Array<{ __typename?: 'RoleCategoryTypeEdge', node?: { __typename?: 'RoleCategoryType', id: string, categoryId: any, name: string, roles: { __typename?: 'RoleTypeConnection', edges: Array<{ __typename?: 'RoleTypeEdge', node?: { __typename?: 'RoleType', id: string, roleId: any, name: string } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLCreateRoleCategoryMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type GQLCreateRoleCategoryMutation = { __typename?: 'Mutation', createRoleCategory?: { __typename?: 'CreateRoleCategory', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, category?: { __typename?: 'RoleCategoryType', categoryId: any, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, roleCategories: { __typename?: 'RoleCategoryTypeConnection', edges: Array<{ __typename?: 'RoleCategoryTypeEdge', node?: { __typename?: 'RoleCategoryType', id: string, categoryId: any, name: string, roles: { __typename?: 'RoleTypeConnection', edges: Array<{ __typename?: 'RoleTypeEdge', node?: { __typename?: 'RoleType', id: string, roleId: any, name: string } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLEditRoleCategoryMutationVariables = Exact<{
  categoryId: Scalars['IDInt'];
  name: Scalars['String'];
}>;


export type GQLEditRoleCategoryMutation = { __typename?: 'Mutation', editRoleCategory?: { __typename?: 'EditRoleCategory', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, category?: { __typename?: 'RoleCategoryType', categoryId: any, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, roleCategories: { __typename?: 'RoleCategoryTypeConnection', edges: Array<{ __typename?: 'RoleCategoryTypeEdge', node?: { __typename?: 'RoleCategoryType', id: string, categoryId: any, name: string, roles: { __typename?: 'RoleTypeConnection', edges: Array<{ __typename?: 'RoleTypeEdge', node?: { __typename?: 'RoleType', id: string, roleId: any, name: string } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLDeleteRoleCategoryMutationVariables = Exact<{
  categoryId: Scalars['IDInt'];
}>;


export type GQLDeleteRoleCategoryMutation = { __typename?: 'Mutation', deleteRoleCategory?: { __typename?: 'DeleteRoleCategory', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, company?: { __typename?: 'CompanyType', id: string, roleCategories: { __typename?: 'RoleCategoryTypeConnection', edges: Array<{ __typename?: 'RoleCategoryTypeEdge', node?: { __typename?: 'RoleCategoryType', id: string, categoryId: any, name: string, roles: { __typename?: 'RoleTypeConnection', edges: Array<{ __typename?: 'RoleTypeEdge', node?: { __typename?: 'RoleType', id: string, roleId: any, name: string } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLCreateProductMutationVariables = Exact<{
  categoryId: Scalars['IDInt'];
  name: Scalars['String'];
}>;


export type GQLCreateProductMutation = { __typename?: 'Mutation', createProduct?: { __typename?: 'CreateProduct', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, product?: { __typename?: 'ProductType', productId?: any | null | undefined, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLEditProductMutationVariables = Exact<{
  productId: Scalars['IDInt'];
  name: Scalars['String'];
}>;


export type GQLEditProductMutation = { __typename?: 'Mutation', editProduct?: { __typename?: 'EditProduct', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, product?: { __typename?: 'ProductType', productId?: any | null | undefined, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLDeleteProductMutationVariables = Exact<{
  productId: Scalars['IDInt'];
  force?: InputMaybe<Scalars['Boolean']>;
}>;


export type GQLDeleteProductMutation = { __typename?: 'Mutation', deleteProduct?: { __typename?: 'DeleteProduct', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLCreateProductCategoryMutationVariables = Exact<{
  name: Scalars['String'];
  hidden?: InputMaybe<Scalars['Boolean']>;
  groupingId?: InputMaybe<Scalars['IDInt']>;
}>;


export type GQLCreateProductCategoryMutation = { __typename?: 'Mutation', createProductCategory?: { __typename?: 'CreateProductCategory', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, category?: { __typename?: 'ProductCategoryType', categoryId?: any | null | undefined, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLEditProductCategoryMutationVariables = Exact<{
  categoryId: Scalars['IDInt'];
  name: Scalars['String'];
  hidden?: InputMaybe<Scalars['Boolean']>;
}>;


export type GQLEditProductCategoryMutation = { __typename?: 'Mutation', editProductCategory?: { __typename?: 'EditProductCategory', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, category?: { __typename?: 'ProductCategoryType', categoryId?: any | null | undefined, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLDeleteProductCategoryMutationVariables = Exact<{
  categoryId: Scalars['IDInt'];
}>;


export type GQLDeleteProductCategoryMutation = { __typename?: 'Mutation', deleteProductCategory?: { __typename?: 'DeleteProductCategory', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLCreateProductCategoryGroupingMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type GQLCreateProductCategoryGroupingMutation = { __typename?: 'Mutation', createProductCategoryGrouping?: { __typename?: 'CreateProductCategoryGrouping', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, grouping?: { __typename?: 'ProductCategoryGroupingType', groupingId?: any | null | undefined, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLEditProductCategoryGroupingMutationVariables = Exact<{
  groupingId: Scalars['IDInt'];
  name: Scalars['String'];
}>;


export type GQLEditProductCategoryGroupingMutation = { __typename?: 'Mutation', editProductCategoryGrouping?: { __typename?: 'EditProductCategoryGrouping', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, grouping?: { __typename?: 'ProductCategoryGroupingType', groupingId?: any | null | undefined, name: string } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLDeleteProductCategoryGroupingMutationVariables = Exact<{
  groupingId: Scalars['IDInt'];
}>;


export type GQLDeleteProductCategoryGroupingMutation = { __typename?: 'Mutation', deleteProductCategoryGrouping?: { __typename?: 'DeleteProductCategoryGrouping', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLMoveProductMutationVariables = Exact<{
  productId: Scalars['IDInt'];
  newOrder: Array<InputMaybe<GQLSortObject>> | InputMaybe<GQLSortObject>;
  categoryId: Scalars['IDInt'];
}>;


export type GQLMoveProductMutation = { __typename?: 'Mutation', moveProduct?: { __typename?: 'MoveProduct', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLMoveCategoryMutationVariables = Exact<{
  categoryId: Scalars['IDInt'];
  newOrder: Array<InputMaybe<GQLSortObject>> | InputMaybe<GQLSortObject>;
  groupingId?: InputMaybe<Scalars['IDInt']>;
}>;


export type GQLMoveCategoryMutation = { __typename?: 'Mutation', moveCategory?: { __typename?: 'MoveCategory', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLSetProductOrderMutationVariables = Exact<{
  newOrder: Array<InputMaybe<GQLSortObject>> | InputMaybe<GQLSortObject>;
}>;


export type GQLSetProductOrderMutation = { __typename?: 'Mutation', setProductOrder?: { __typename?: 'SetProductOrder', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined };

export type GQLGetBrandingQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLGetBrandingQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyType', id: string, companyId?: any | null | undefined, companyConfig?: { __typename?: 'CompanyConfigType', backgroundColor?: string | null | undefined, companyLogo?: string | null | undefined, companyLogoTitle?: string | null | undefined, companyLogoUrl?: string | null | undefined, highlightColor?: string | null | undefined, id: string, lightBackground: boolean, lightNavbar: boolean, navbarLogo?: string | null | undefined, onboardingWizardEnabled?: boolean | null | undefined, primaryButtonColor?: string | null | undefined, primaryColor?: string | null | undefined, adminTagCreation?: boolean | null | undefined, aboutRolesUrl?: string | null | undefined, aboutProductsUrl?: string | null | undefined, areaEnabled: boolean, productOrder?: any | null | undefined, homepageText?: string | null | undefined } | null | undefined } | null | undefined };

export type GQLGetHomepageTextQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLGetHomepageTextQuery = { __typename?: 'Query', companyConfig?: { __typename?: 'CompanyConfigType', id: string, homepageText?: string | null | undefined, mobileHomepageText?: string | null | undefined, mobileHomepageMessage: string } | null | undefined };

export type GQLSetHomepageTextMutationVariables = Exact<{
  mobileHomepageText?: InputMaybe<Scalars['String']>;
  mobileHomepageMessage?: InputMaybe<Scalars['String']>;
  homepageText?: InputMaybe<Scalars['String']>;
}>;


export type GQLSetHomepageTextMutation = { __typename?: 'Mutation', setHomepageText?: { __typename?: 'SetHomepageText', ok?: boolean | null | undefined, error?: { __typename?: 'Error', code?: string | null | undefined, message?: string | null | undefined } | null | undefined, companyConfig?: { __typename?: 'CompanyConfigType', id: string, homepageText?: string | null | undefined, mobileHomepageText?: string | null | undefined, mobileHomepageMessage: string } | null | undefined } | null | undefined };

export type GQLGetCompanyAllowQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLGetCompanyAllowQuery = {
  __typename?: 'Query',
  company?: {
    __typename?: 'CompanyType',
    id: string,
    companyId?: any | null | undefined,
    allowByIp?: boolean | null | undefined,
    allowCidrs?: string | null | undefined
  } | null | undefined
};

export type GQLSetCompanyAllowMutationVariables = Exact<{
  allowByIp?: InputMaybe<Scalars['Boolean']>;
  allowCidrs?: InputMaybe<Scalars['String']>;
}>;


export type GQLSetCompanyAllowMutation = {
  __typename?: 'Mutation',
  setCompany?: {
    __typename?: 'CompanyMutation',
    ok?: boolean | null | undefined,
    error?: {
      __typename?: 'Error',
      code?: string | null | undefined,
      message?: string | null | undefined
    } | null | undefined,
    company?: {
      __typename?: 'CompanyType',
      id: string,
      companyId?: any | null | undefined,
      allowByIp?: boolean | null | undefined,
      allowCidrs?: string | null | undefined
    } | null | undefined
  } | null | undefined
};

export type GQLGetSessionTimeoutQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLGetSessionTimeoutQuery = {
  __typename?: 'Query',
  company?: {
    __typename?: 'CompanyType',
    id: string,
    companyId?: any | null | undefined,
    sessionTimeout?: number | null | undefined
  } | null | undefined
};

export type GQLSetSessionTimeoutMutationVariables = Exact<{
  timeout?: InputMaybe<Scalars['Int']>;
}>;


export type GQLSetSessionTimeoutMutation = {
  __typename?: 'Mutation',
  setCompany?: {
    __typename?: 'CompanyMutation',
    ok?: boolean | null | undefined,
    error?: {
      __typename?: 'Error',
      code?: string | null | undefined,
      message?: string | null | undefined
    } | null | undefined,
    company?: {
      __typename?: 'CompanyType',
      id: string,
      companyId?: any | null | undefined,
      sessionTimeout?: number | null | undefined
    } | null | undefined
  } | null | undefined
};

export type GQLSetCompanyConfigMutationVariables = Exact<{
  adminTagCreation?: InputMaybe<Scalars['String']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
  companyLogoTitle?: InputMaybe<Scalars['String']>;
  companyLogoUrl?: InputMaybe<Scalars['String']>;
  highlightColor?: InputMaybe<Scalars['String']>;
  lightBackground?: InputMaybe<Scalars['Boolean']>;
  lightNavbar?: InputMaybe<Scalars['Boolean']>;
  navbarLogo?: InputMaybe<Scalars['Upload']>;
  onboardingWizardEnabled?: InputMaybe<Scalars['String']>;
  primaryButtonColor?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
}>;


export type GQLSetCompanyConfigMutation = { __typename?: 'Mutation', setCompanyConfig?: { __typename?: 'CompanyConfigMutation', companyConfig?: { __typename?: 'CompanyConfigType', adminTagCreation?: boolean | null | undefined, backgroundColor?: string | null | undefined, companyLogo?: string | null | undefined, companyLogoTitle?: string | null | undefined, companyLogoUrl?: string | null | undefined, highlightColor?: string | null | undefined, id: string, lightBackground: boolean, lightNavbar: boolean, navbarLogo?: string | null | undefined, onboardingWizardEnabled?: boolean | null | undefined, primaryButtonColor?: string | null | undefined, primaryColor?: string | null | undefined } | null | undefined } | null | undefined };

export type GQLGetProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLGetProductsQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyType', companyId?: any | null | undefined, id: string, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', productId?: any | null | undefined, name: string, companyId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined };

export type GQLProductCompanyPartsFragment = { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } };

export type GQLGetProductDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLGetProductDataQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyType', id: string, companyConfig?: { __typename?: 'CompanyConfigType', productOrder?: any | null | undefined } | null | undefined, productCategoryGroupings: { __typename?: 'ProductCategoryGroupingTypeConnection', edges: Array<{ __typename?: 'ProductCategoryGroupingTypeEdge', node?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined, name: string, categories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> }, productCategories: { __typename?: 'ProductCategoryTypeConnection', edges: Array<{ __typename?: 'ProductCategoryTypeEdge', node?: { __typename?: 'ProductCategoryType', id: string, categoryId?: any | null | undefined, name: string, hidden: boolean, grouping?: { __typename?: 'ProductCategoryGroupingType', id: string, groupingId?: any | null | undefined } | null | undefined, products: { __typename?: 'ProductTypeConnection', edges: Array<{ __typename?: 'ProductTypeEdge', node?: { __typename?: 'ProductType', id: string, name: string, productId?: any | null | undefined } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined };

export type GQLRoleCompanyPartsFragment = { __typename?: 'CompanyType', id: string, roleCategories: { __typename?: 'RoleCategoryTypeConnection', edges: Array<{ __typename?: 'RoleCategoryTypeEdge', node?: { __typename?: 'RoleCategoryType', id: string, categoryId: any, name: string, roles: { __typename?: 'RoleTypeConnection', edges: Array<{ __typename?: 'RoleTypeEdge', node?: { __typename?: 'RoleType', id: string, roleId: any, name: string } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } };

export type GQLGetRoleDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLGetRoleDataQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyType', id: string, roleCategories: { __typename?: 'RoleCategoryTypeConnection', edges: Array<{ __typename?: 'RoleCategoryTypeEdge', node?: { __typename?: 'RoleCategoryType', id: string, categoryId: any, name: string, roles: { __typename?: 'RoleTypeConnection', edges: Array<{ __typename?: 'RoleTypeEdge', node?: { __typename?: 'RoleType', id: string, roleId: any, name: string } | null | undefined } | null | undefined> } } | null | undefined } | null | undefined> } } | null | undefined };

export const ErrorPartsFragmentDoc = gql`
    fragment ErrorParts on Error {
  code
  message
}
    `;
export const ProductCompanyPartsFragmentDoc = gql`
    fragment ProductCompanyParts on CompanyType {
  id
  companyConfig {
    productOrder
  }
  productCategoryGroupings {
    edges {
      node {
        id
        groupingId
        name
        categories {
          edges {
            node {
              id
              categoryId
            }
          }
        }
      }
    }
  }
  productCategories {
    edges {
      node {
        id
        categoryId
        name
        hidden
        grouping {
          id
          groupingId
        }
        products {
          edges {
            node {
              id
              name
              productId
            }
          }
        }
      }
    }
  }
}
    `;
export const RoleCompanyPartsFragmentDoc = gql`
    fragment RoleCompanyParts on CompanyType {
  id
  roleCategories {
    edges {
      node {
        id
        categoryId
        name
        roles {
          edges {
            node {
              id
              roleId
              name
            }
          }
        }
      }
    }
  }
}
    `;
export const CreateRoleDocument = gql`
    mutation CreateRole($categoryId: IDInt!, $name: String!) {
  createRole(categoryId: $categoryId, name: $name) {
    ok
    error {
      ...ErrorParts
    }
    role {
      roleId
      name
    }
    company {
      ...RoleCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${RoleCompanyPartsFragmentDoc}`;
export type GQLCreateRoleMutationFn = Apollo.MutationFunction<GQLCreateRoleMutation, GQLCreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateRoleMutation, GQLCreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateRoleMutation, GQLCreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<GQLCreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<GQLCreateRoleMutation, GQLCreateRoleMutationVariables>;
export const EditRoleDocument = gql`
    mutation EditRole($roleId: IDInt!, $name: String, $categoryId: IDInt) {
  editRole(roleId: $roleId, name: $name, categoryId: $categoryId) {
    ok
    error {
      ...ErrorParts
    }
    role {
      roleId
      name
    }
    company {
      ...RoleCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${RoleCompanyPartsFragmentDoc}`;
export type GQLEditRoleMutationFn = Apollo.MutationFunction<GQLEditRoleMutation, GQLEditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      name: // value for 'name'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: Apollo.MutationHookOptions<GQLEditRoleMutation, GQLEditRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLEditRoleMutation, GQLEditRoleMutationVariables>(EditRoleDocument, options);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<GQLEditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<GQLEditRoleMutation, GQLEditRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($roleId: IDInt!, $force: Boolean) {
  deleteRole(roleId: $roleId, force: $force) {
    ok
    error {
      ...ErrorParts
    }
    company {
      ...RoleCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${RoleCompanyPartsFragmentDoc}`;
export type GQLDeleteRoleMutationFn = Apollo.MutationFunction<GQLDeleteRoleMutation, GQLDeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteRoleMutation, GQLDeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteRoleMutation, GQLDeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<GQLDeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<GQLDeleteRoleMutation, GQLDeleteRoleMutationVariables>;
export const CreateRoleCategoryDocument = gql`
    mutation CreateRoleCategory($name: String!) {
  createRoleCategory(name: $name) {
    ok
    error {
      ...ErrorParts
    }
    category {
      categoryId
      name
    }
    company {
      ...RoleCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${RoleCompanyPartsFragmentDoc}`;
export type GQLCreateRoleCategoryMutationFn = Apollo.MutationFunction<GQLCreateRoleCategoryMutation, GQLCreateRoleCategoryMutationVariables>;

/**
 * __useCreateRoleCategoryMutation__
 *
 * To run a mutation, you first call `useCreateRoleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleCategoryMutation, { data, loading, error }] = useCreateRoleCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateRoleCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateRoleCategoryMutation, GQLCreateRoleCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateRoleCategoryMutation, GQLCreateRoleCategoryMutationVariables>(CreateRoleCategoryDocument, options);
      }
export type CreateRoleCategoryMutationHookResult = ReturnType<typeof useCreateRoleCategoryMutation>;
export type CreateRoleCategoryMutationResult = Apollo.MutationResult<GQLCreateRoleCategoryMutation>;
export type CreateRoleCategoryMutationOptions = Apollo.BaseMutationOptions<GQLCreateRoleCategoryMutation, GQLCreateRoleCategoryMutationVariables>;
export const EditRoleCategoryDocument = gql`
    mutation EditRoleCategory($categoryId: IDInt!, $name: String!) {
  editRoleCategory(name: $name, categoryId: $categoryId) {
    ok
    error {
      ...ErrorParts
    }
    category {
      categoryId
      name
    }
    company {
      ...RoleCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${RoleCompanyPartsFragmentDoc}`;
export type GQLEditRoleCategoryMutationFn = Apollo.MutationFunction<GQLEditRoleCategoryMutation, GQLEditRoleCategoryMutationVariables>;

/**
 * __useEditRoleCategoryMutation__
 *
 * To run a mutation, you first call `useEditRoleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleCategoryMutation, { data, loading, error }] = useEditRoleCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditRoleCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLEditRoleCategoryMutation, GQLEditRoleCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLEditRoleCategoryMutation, GQLEditRoleCategoryMutationVariables>(EditRoleCategoryDocument, options);
      }
export type EditRoleCategoryMutationHookResult = ReturnType<typeof useEditRoleCategoryMutation>;
export type EditRoleCategoryMutationResult = Apollo.MutationResult<GQLEditRoleCategoryMutation>;
export type EditRoleCategoryMutationOptions = Apollo.BaseMutationOptions<GQLEditRoleCategoryMutation, GQLEditRoleCategoryMutationVariables>;
export const DeleteRoleCategoryDocument = gql`
    mutation DeleteRoleCategory($categoryId: IDInt!) {
  deleteRoleCategory(categoryId: $categoryId) {
    ok
    error {
      ...ErrorParts
    }
    company {
      ...RoleCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${RoleCompanyPartsFragmentDoc}`;
export type GQLDeleteRoleCategoryMutationFn = Apollo.MutationFunction<GQLDeleteRoleCategoryMutation, GQLDeleteRoleCategoryMutationVariables>;

/**
 * __useDeleteRoleCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteRoleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleCategoryMutation, { data, loading, error }] = useDeleteRoleCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteRoleCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteRoleCategoryMutation, GQLDeleteRoleCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteRoleCategoryMutation, GQLDeleteRoleCategoryMutationVariables>(DeleteRoleCategoryDocument, options);
      }
export type DeleteRoleCategoryMutationHookResult = ReturnType<typeof useDeleteRoleCategoryMutation>;
export type DeleteRoleCategoryMutationResult = Apollo.MutationResult<GQLDeleteRoleCategoryMutation>;
export type DeleteRoleCategoryMutationOptions = Apollo.BaseMutationOptions<GQLDeleteRoleCategoryMutation, GQLDeleteRoleCategoryMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($categoryId: IDInt!, $name: String!) {
  createProduct(categoryId: $categoryId, name: $name) {
    ok
    error {
      ...ErrorParts
    }
    product {
      productId
      name
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLCreateProductMutationFn = Apollo.MutationFunction<GQLCreateProductMutation, GQLCreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateProductMutation, GQLCreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateProductMutation, GQLCreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<GQLCreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<GQLCreateProductMutation, GQLCreateProductMutationVariables>;
export const EditProductDocument = gql`
    mutation EditProduct($productId: IDInt!, $name: String!) {
  editProduct(productId: $productId, name: $name) {
    ok
    error {
      ...ErrorParts
    }
    product {
      productId
      name
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLEditProductMutationFn = Apollo.MutationFunction<GQLEditProductMutation, GQLEditProductMutationVariables>;

/**
 * __useEditProductMutation__
 *
 * To run a mutation, you first call `useEditProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductMutation, { data, loading, error }] = useEditProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditProductMutation(baseOptions?: Apollo.MutationHookOptions<GQLEditProductMutation, GQLEditProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLEditProductMutation, GQLEditProductMutationVariables>(EditProductDocument, options);
      }
export type EditProductMutationHookResult = ReturnType<typeof useEditProductMutation>;
export type EditProductMutationResult = Apollo.MutationResult<GQLEditProductMutation>;
export type EditProductMutationOptions = Apollo.BaseMutationOptions<GQLEditProductMutation, GQLEditProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($productId: IDInt!, $force: Boolean) {
  deleteProduct(productId: $productId, force: $force) {
    ok
    error {
      ...ErrorParts
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLDeleteProductMutationFn = Apollo.MutationFunction<GQLDeleteProductMutation, GQLDeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteProductMutation, GQLDeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteProductMutation, GQLDeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<GQLDeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<GQLDeleteProductMutation, GQLDeleteProductMutationVariables>;
export const CreateProductCategoryDocument = gql`
    mutation CreateProductCategory($name: String!, $hidden: Boolean, $groupingId: IDInt) {
  createProductCategory(name: $name, hidden: $hidden, groupingId: $groupingId) {
    ok
    error {
      ...ErrorParts
    }
    category {
      categoryId
      name
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLCreateProductCategoryMutationFn = Apollo.MutationFunction<GQLCreateProductCategoryMutation, GQLCreateProductCategoryMutationVariables>;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      hidden: // value for 'hidden'
 *      groupingId: // value for 'groupingId'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateProductCategoryMutation, GQLCreateProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateProductCategoryMutation, GQLCreateProductCategoryMutationVariables>(CreateProductCategoryDocument, options);
      }
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
export type CreateProductCategoryMutationResult = Apollo.MutationResult<GQLCreateProductCategoryMutation>;
export type CreateProductCategoryMutationOptions = Apollo.BaseMutationOptions<GQLCreateProductCategoryMutation, GQLCreateProductCategoryMutationVariables>;
export const EditProductCategoryDocument = gql`
    mutation EditProductCategory($categoryId: IDInt!, $name: String!, $hidden: Boolean) {
  editProductCategory(name: $name, categoryId: $categoryId, hidden: $hidden) {
    ok
    error {
      ...ErrorParts
    }
    category {
      categoryId
      name
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLEditProductCategoryMutationFn = Apollo.MutationFunction<GQLEditProductCategoryMutation, GQLEditProductCategoryMutationVariables>;

/**
 * __useEditProductCategoryMutation__
 *
 * To run a mutation, you first call `useEditProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductCategoryMutation, { data, loading, error }] = useEditProductCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *      hidden: // value for 'hidden'
 *   },
 * });
 */
export function useEditProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLEditProductCategoryMutation, GQLEditProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLEditProductCategoryMutation, GQLEditProductCategoryMutationVariables>(EditProductCategoryDocument, options);
      }
export type EditProductCategoryMutationHookResult = ReturnType<typeof useEditProductCategoryMutation>;
export type EditProductCategoryMutationResult = Apollo.MutationResult<GQLEditProductCategoryMutation>;
export type EditProductCategoryMutationOptions = Apollo.BaseMutationOptions<GQLEditProductCategoryMutation, GQLEditProductCategoryMutationVariables>;
export const DeleteProductCategoryDocument = gql`
    mutation DeleteProductCategory($categoryId: IDInt!) {
  deleteProductCategory(categoryId: $categoryId) {
    ok
    error {
      ...ErrorParts
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLDeleteProductCategoryMutationFn = Apollo.MutationFunction<GQLDeleteProductCategoryMutation, GQLDeleteProductCategoryMutationVariables>;

/**
 * __useDeleteProductCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductCategoryMutation, { data, loading, error }] = useDeleteProductCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteProductCategoryMutation, GQLDeleteProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteProductCategoryMutation, GQLDeleteProductCategoryMutationVariables>(DeleteProductCategoryDocument, options);
      }
export type DeleteProductCategoryMutationHookResult = ReturnType<typeof useDeleteProductCategoryMutation>;
export type DeleteProductCategoryMutationResult = Apollo.MutationResult<GQLDeleteProductCategoryMutation>;
export type DeleteProductCategoryMutationOptions = Apollo.BaseMutationOptions<GQLDeleteProductCategoryMutation, GQLDeleteProductCategoryMutationVariables>;
export const CreateProductCategoryGroupingDocument = gql`
    mutation CreateProductCategoryGrouping($name: String!) {
  createProductCategoryGrouping(name: $name) {
    ok
    error {
      ...ErrorParts
    }
    grouping {
      groupingId
      name
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLCreateProductCategoryGroupingMutationFn = Apollo.MutationFunction<GQLCreateProductCategoryGroupingMutation, GQLCreateProductCategoryGroupingMutationVariables>;

/**
 * __useCreateProductCategoryGroupingMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryGroupingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryGroupingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryGroupingMutation, { data, loading, error }] = useCreateProductCategoryGroupingMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProductCategoryGroupingMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateProductCategoryGroupingMutation, GQLCreateProductCategoryGroupingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateProductCategoryGroupingMutation, GQLCreateProductCategoryGroupingMutationVariables>(CreateProductCategoryGroupingDocument, options);
      }
export type CreateProductCategoryGroupingMutationHookResult = ReturnType<typeof useCreateProductCategoryGroupingMutation>;
export type CreateProductCategoryGroupingMutationResult = Apollo.MutationResult<GQLCreateProductCategoryGroupingMutation>;
export type CreateProductCategoryGroupingMutationOptions = Apollo.BaseMutationOptions<GQLCreateProductCategoryGroupingMutation, GQLCreateProductCategoryGroupingMutationVariables>;
export const EditProductCategoryGroupingDocument = gql`
    mutation EditProductCategoryGrouping($groupingId: IDInt!, $name: String!) {
  editProductCategoryGrouping(name: $name, groupingId: $groupingId) {
    ok
    error {
      ...ErrorParts
    }
    grouping {
      groupingId
      name
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLEditProductCategoryGroupingMutationFn = Apollo.MutationFunction<GQLEditProductCategoryGroupingMutation, GQLEditProductCategoryGroupingMutationVariables>;

/**
 * __useEditProductCategoryGroupingMutation__
 *
 * To run a mutation, you first call `useEditProductCategoryGroupingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductCategoryGroupingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductCategoryGroupingMutation, { data, loading, error }] = useEditProductCategoryGroupingMutation({
 *   variables: {
 *      groupingId: // value for 'groupingId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditProductCategoryGroupingMutation(baseOptions?: Apollo.MutationHookOptions<GQLEditProductCategoryGroupingMutation, GQLEditProductCategoryGroupingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLEditProductCategoryGroupingMutation, GQLEditProductCategoryGroupingMutationVariables>(EditProductCategoryGroupingDocument, options);
      }
export type EditProductCategoryGroupingMutationHookResult = ReturnType<typeof useEditProductCategoryGroupingMutation>;
export type EditProductCategoryGroupingMutationResult = Apollo.MutationResult<GQLEditProductCategoryGroupingMutation>;
export type EditProductCategoryGroupingMutationOptions = Apollo.BaseMutationOptions<GQLEditProductCategoryGroupingMutation, GQLEditProductCategoryGroupingMutationVariables>;
export const DeleteProductCategoryGroupingDocument = gql`
    mutation DeleteProductCategoryGrouping($groupingId: IDInt!) {
  deleteProductCategoryGrouping(groupingId: $groupingId) {
    ok
    error {
      ...ErrorParts
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLDeleteProductCategoryGroupingMutationFn = Apollo.MutationFunction<GQLDeleteProductCategoryGroupingMutation, GQLDeleteProductCategoryGroupingMutationVariables>;

/**
 * __useDeleteProductCategoryGroupingMutation__
 *
 * To run a mutation, you first call `useDeleteProductCategoryGroupingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductCategoryGroupingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductCategoryGroupingMutation, { data, loading, error }] = useDeleteProductCategoryGroupingMutation({
 *   variables: {
 *      groupingId: // value for 'groupingId'
 *   },
 * });
 */
export function useDeleteProductCategoryGroupingMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteProductCategoryGroupingMutation, GQLDeleteProductCategoryGroupingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteProductCategoryGroupingMutation, GQLDeleteProductCategoryGroupingMutationVariables>(DeleteProductCategoryGroupingDocument, options);
      }
export type DeleteProductCategoryGroupingMutationHookResult = ReturnType<typeof useDeleteProductCategoryGroupingMutation>;
export type DeleteProductCategoryGroupingMutationResult = Apollo.MutationResult<GQLDeleteProductCategoryGroupingMutation>;
export type DeleteProductCategoryGroupingMutationOptions = Apollo.BaseMutationOptions<GQLDeleteProductCategoryGroupingMutation, GQLDeleteProductCategoryGroupingMutationVariables>;
export const MoveProductDocument = gql`
    mutation MoveProduct($productId: IDInt!, $newOrder: [SortObject]!, $categoryId: IDInt!) {
  moveProduct(productId: $productId, newOrder: $newOrder, categoryId: $categoryId) {
    ok
    error {
      ...ErrorParts
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLMoveProductMutationFn = Apollo.MutationFunction<GQLMoveProductMutation, GQLMoveProductMutationVariables>;

/**
 * __useMoveProductMutation__
 *
 * To run a mutation, you first call `useMoveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProductMutation, { data, loading, error }] = useMoveProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      newOrder: // value for 'newOrder'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useMoveProductMutation(baseOptions?: Apollo.MutationHookOptions<GQLMoveProductMutation, GQLMoveProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLMoveProductMutation, GQLMoveProductMutationVariables>(MoveProductDocument, options);
      }
export type MoveProductMutationHookResult = ReturnType<typeof useMoveProductMutation>;
export type MoveProductMutationResult = Apollo.MutationResult<GQLMoveProductMutation>;
export type MoveProductMutationOptions = Apollo.BaseMutationOptions<GQLMoveProductMutation, GQLMoveProductMutationVariables>;
export const MoveCategoryDocument = gql`
    mutation MoveCategory($categoryId: IDInt!, $newOrder: [SortObject]!, $groupingId: IDInt) {
  moveCategory(
    categoryId: $categoryId
    newOrder: $newOrder
    groupingId: $groupingId
  ) {
    ok
    error {
      ...ErrorParts
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLMoveCategoryMutationFn = Apollo.MutationFunction<GQLMoveCategoryMutation, GQLMoveCategoryMutationVariables>;

/**
 * __useMoveCategoryMutation__
 *
 * To run a mutation, you first call `useMoveCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCategoryMutation, { data, loading, error }] = useMoveCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      newOrder: // value for 'newOrder'
 *      groupingId: // value for 'groupingId'
 *   },
 * });
 */
export function useMoveCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLMoveCategoryMutation, GQLMoveCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLMoveCategoryMutation, GQLMoveCategoryMutationVariables>(MoveCategoryDocument, options);
      }
export type MoveCategoryMutationHookResult = ReturnType<typeof useMoveCategoryMutation>;
export type MoveCategoryMutationResult = Apollo.MutationResult<GQLMoveCategoryMutation>;
export type MoveCategoryMutationOptions = Apollo.BaseMutationOptions<GQLMoveCategoryMutation, GQLMoveCategoryMutationVariables>;
export const SetProductOrderDocument = gql`
    mutation SetProductOrder($newOrder: [SortObject]!) {
  setProductOrder(newOrder: $newOrder) {
    ok
    error {
      ...ErrorParts
    }
    company {
      ...ProductCompanyParts
    }
  }
}
    ${ErrorPartsFragmentDoc}
${ProductCompanyPartsFragmentDoc}`;
export type GQLSetProductOrderMutationFn = Apollo.MutationFunction<GQLSetProductOrderMutation, GQLSetProductOrderMutationVariables>;

/**
 * __useSetProductOrderMutation__
 *
 * To run a mutation, you first call `useSetProductOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProductOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProductOrderMutation, { data, loading, error }] = useSetProductOrderMutation({
 *   variables: {
 *      newOrder: // value for 'newOrder'
 *   },
 * });
 */
export function useSetProductOrderMutation(baseOptions?: Apollo.MutationHookOptions<GQLSetProductOrderMutation, GQLSetProductOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLSetProductOrderMutation, GQLSetProductOrderMutationVariables>(SetProductOrderDocument, options);
      }
export type SetProductOrderMutationHookResult = ReturnType<typeof useSetProductOrderMutation>;
export type SetProductOrderMutationResult = Apollo.MutationResult<GQLSetProductOrderMutation>;
export type SetProductOrderMutationOptions = Apollo.BaseMutationOptions<GQLSetProductOrderMutation, GQLSetProductOrderMutationVariables>;
export const GetBrandingDocument = gql`
    query GetBranding {
  company {
    id
    companyId
    companyConfig {
      backgroundColor
      companyLogo
      companyLogoTitle
      companyLogoUrl
      highlightColor
      id
      lightBackground
      lightNavbar
      navbarLogo
      onboardingWizardEnabled
      primaryButtonColor
      primaryColor
      adminTagCreation
      aboutRolesUrl
      aboutProductsUrl
      areaEnabled
      productOrder
      homepageText
    }
  }
}
    `;

/**
 * __useGetBrandingQuery__
 *
 * To run a query within a React component, call `useGetBrandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandingQuery(baseOptions?: Apollo.QueryHookOptions<GQLGetBrandingQuery, GQLGetBrandingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetBrandingQuery, GQLGetBrandingQueryVariables>(GetBrandingDocument, options);
      }
export function useGetBrandingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetBrandingQuery, GQLGetBrandingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetBrandingQuery, GQLGetBrandingQueryVariables>(GetBrandingDocument, options);
        }
export type GetBrandingQueryHookResult = ReturnType<typeof useGetBrandingQuery>;
export type GetBrandingLazyQueryHookResult = ReturnType<typeof useGetBrandingLazyQuery>;
export type GetBrandingQueryResult = Apollo.QueryResult<GQLGetBrandingQuery, GQLGetBrandingQueryVariables>;
export const GetHomepageTextDocument = gql`
    query GetHomepageText {
  companyConfig {
    id
    homepageText
    mobileHomepageText
    mobileHomepageMessage
  }
}
    `;

/**
 * __useGetHomepageTextQuery__
 *
 * To run a query within a React component, call `useGetHomepageTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomepageTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomepageTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomepageTextQuery(baseOptions?: Apollo.QueryHookOptions<GQLGetHomepageTextQuery, GQLGetHomepageTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetHomepageTextQuery, GQLGetHomepageTextQueryVariables>(GetHomepageTextDocument, options);
      }
export function useGetHomepageTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetHomepageTextQuery, GQLGetHomepageTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetHomepageTextQuery, GQLGetHomepageTextQueryVariables>(GetHomepageTextDocument, options);
        }
export type GetHomepageTextQueryHookResult = ReturnType<typeof useGetHomepageTextQuery>;
export type GetHomepageTextLazyQueryHookResult = ReturnType<typeof useGetHomepageTextLazyQuery>;
export type GetHomepageTextQueryResult = Apollo.QueryResult<GQLGetHomepageTextQuery, GQLGetHomepageTextQueryVariables>;
export const SetHomepageTextDocument = gql`
    mutation SetHomepageText($mobileHomepageText: String, $mobileHomepageMessage: String, $homepageText: String) {
  setHomepageText(
    mobileHomepageText: $mobileHomepageText
    mobileHomepageMessage: $mobileHomepageMessage
    homepageText: $homepageText
  ) {
    ok
    error {
      ...ErrorParts
    }
    companyConfig {
      id
      homepageText
      mobileHomepageText
      mobileHomepageMessage
    }
  }
}
    ${ErrorPartsFragmentDoc}`;
export type GQLSetHomepageTextMutationFn = Apollo.MutationFunction<GQLSetHomepageTextMutation, GQLSetHomepageTextMutationVariables>;

/**
 * __useSetHomepageTextMutation__
 *
 * To run a mutation, you first call `useSetHomepageTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHomepageTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHomepageTextMutation, { data, loading, error }] = useSetHomepageTextMutation({
 *   variables: {
 *      mobileHomepageText: // value for 'mobileHomepageText'
 *      mobileHomepageMessage: // value for 'mobileHomepageMessage'
 *      homepageText: // value for 'homepageText'
 *   },
 * });
 */
export function useSetHomepageTextMutation(baseOptions?: Apollo.MutationHookOptions<GQLSetHomepageTextMutation, GQLSetHomepageTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLSetHomepageTextMutation, GQLSetHomepageTextMutationVariables>(SetHomepageTextDocument, options);
      }
export type SetHomepageTextMutationHookResult = ReturnType<typeof useSetHomepageTextMutation>;
export type SetHomepageTextMutationResult = Apollo.MutationResult<GQLSetHomepageTextMutation>;
export type SetHomepageTextMutationOptions = Apollo.BaseMutationOptions<GQLSetHomepageTextMutation, GQLSetHomepageTextMutationVariables>;
export const GetCompanyAllowDocument = gql`
    query GetCompanyAllow {
      company {
        id
        companyId
        allowByIp
        allowCidrs
      }
    }
`;

/**
 * __useGetCompanyAllowQuery__
 *
 * To run a query within a React component, call `useGetCompanyAllowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAllowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAllowQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyAllowQuery(baseOptions?: Apollo.QueryHookOptions<GQLGetCompanyAllowQuery, GQLGetCompanyAllowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GQLGetCompanyAllowQuery, GQLGetCompanyAllowQueryVariables>(GetCompanyAllowDocument, options)
}
export function useGetCompanyAllowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetCompanyAllowQuery, GQLGetCompanyAllowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GQLGetCompanyAllowQuery, GQLGetCompanyAllowQueryVariables>(GetCompanyAllowDocument, options)
}
export type GetCompanyAllowQueryHookResult = ReturnType<typeof useGetCompanyAllowQuery>;
export type GetCompanyAllowLazyQueryHookResult = ReturnType<typeof useGetCompanyAllowLazyQuery>;
export type GetCompanyAllowQueryResult = Apollo.QueryResult<GQLGetCompanyAllowQuery, GQLGetCompanyAllowQueryVariables>;
export const SetCompanyAllowDocument = gql`
    mutation SetCompanyAllow($allowByIp: Boolean, $allowCidrs: String) {
      setCompany(allowByIp: $allowByIp, allowCidrs: $allowCidrs) {
        ok
        error {
          ...ErrorParts
        }
        company {
          id
          companyId
          allowByIp
          allowCidrs
        }
      }
    }
${ErrorPartsFragmentDoc}`;
export type GQLSetCompanyAllowMutationFn = Apollo.MutationFunction<GQLSetCompanyAllowMutation, GQLSetCompanyAllowMutationVariables>;

/**
 * __useSetCompanyAllowMutation__
 *
 * To run a mutation, you first call `useSetCompanyAllowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanyAllowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompanyAllowMutation, { data, loading, error }] = useSetCompanyAllowMutation({
 *   variables: {
 *      allowByIp: // value for 'allowByIp'
 *      allowCidrs: // value for 'allowCidrs'
 *   },
 * });
 */
export function useSetCompanyAllowMutation(baseOptions?: Apollo.MutationHookOptions<GQLSetCompanyAllowMutation, GQLSetCompanyAllowMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GQLSetCompanyAllowMutation, GQLSetCompanyAllowMutationVariables>(SetCompanyAllowDocument, options)
}
export type SetCompanyAllowMutationHookResult = ReturnType<typeof useSetCompanyAllowMutation>;
export type SetCompanyAllowMutationResult = Apollo.MutationResult<GQLSetCompanyAllowMutation>;
export type SetCompanyAllowMutationOptions = Apollo.BaseMutationOptions<GQLSetCompanyAllowMutation, GQLSetCompanyAllowMutationVariables>;
export const GetSessionTimeoutDocument = gql`
  query GetSessionTimeout {
    company {
      id
      companyId
      sessionTimeout
    }
  }
`

/**
 * __useGetSessionTimeoutQuery__
 *
 * To run a query within a React component, call `useGetSessionTimeoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionTimeoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionTimeoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionTimeoutQuery(baseOptions?: Apollo.QueryHookOptions<GQLGetSessionTimeoutQuery, GQLGetSessionTimeoutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GQLGetSessionTimeoutQuery, GQLGetSessionTimeoutQueryVariables>(GetSessionTimeoutDocument, options)
}

export function useGetSessionTimeoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetSessionTimeoutQuery, GQLGetSessionTimeoutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GQLGetSessionTimeoutQuery, GQLGetSessionTimeoutQueryVariables>(GetSessionTimeoutDocument, options)
}

export type GetSessionTimeoutQueryHookResult = ReturnType<typeof useGetSessionTimeoutQuery>;
export type GetSessionTimeoutLazyQueryHookResult = ReturnType<typeof useGetSessionTimeoutLazyQuery>;
export type GetSessionTimeoutQueryResult = Apollo.QueryResult<GQLGetSessionTimeoutQuery, GQLGetSessionTimeoutQueryVariables>;
export const SetSessionTimeoutDocument = gql`
  mutation SetSessionTimeout($timeout: Int) {
    setCompany(sessionTimeout: $timeout) {
      ok
      error {
        ...ErrorParts
      }
      company {
        id
        companyId
        sessionTimeout
      }
    }
  }
${ErrorPartsFragmentDoc}`
export type GQLSetSessionTimeoutMutationFn = Apollo.MutationFunction<GQLSetSessionTimeoutMutation, GQLSetSessionTimeoutMutationVariables>;

/**
 * __useSetSessionTimeoutMutation__
 *
 * To run a mutation, you first call `useSetSessionTimeoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSessionTimeoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSessionTimeoutMutation, { data, loading, error }] = useSetSessionTimeoutMutation({
 *   variables: {
 *      timeout: // value for 'timeout'
 *   },
 * });
 */
export function useSetSessionTimeoutMutation(baseOptions?: Apollo.MutationHookOptions<GQLSetSessionTimeoutMutation, GQLSetSessionTimeoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GQLSetSessionTimeoutMutation, GQLSetSessionTimeoutMutationVariables>(SetSessionTimeoutDocument, options)
}

export type SetSessionTimeoutMutationHookResult = ReturnType<typeof useSetSessionTimeoutMutation>;
export type SetSessionTimeoutMutationResult = Apollo.MutationResult<GQLSetSessionTimeoutMutation>;
export type SetSessionTimeoutMutationOptions = Apollo.BaseMutationOptions<GQLSetSessionTimeoutMutation, GQLSetSessionTimeoutMutationVariables>;
export const SetCompanyConfigDocument = gql`
    mutation SetCompanyConfig($adminTagCreation: String, $backgroundColor: String, $companyLogo: Upload, $companyLogoTitle: String, $companyLogoUrl: String, $highlightColor: String, $lightBackground: Boolean, $lightNavbar: Boolean, $navbarLogo: Upload, $onboardingWizardEnabled: String, $primaryButtonColor: String, $primaryColor: String) {
  setCompanyConfig(
    adminTagCreation: $adminTagCreation
    backgroundColor: $backgroundColor
    companyLogo: $companyLogo
    companyLogoTitle: $companyLogoTitle
    companyLogoUrl: $companyLogoUrl
    highlightColor: $highlightColor
    lightBackground: $lightBackground
    lightNavbar: $lightNavbar
    navbarLogo: $navbarLogo
    onboardingWizardEnabled: $onboardingWizardEnabled
    primaryButtonColor: $primaryButtonColor
    primaryColor: $primaryColor
  ) {
    companyConfig {
      adminTagCreation
      backgroundColor
      companyLogo
      companyLogoTitle
      companyLogoUrl
      highlightColor
      id
      lightBackground
      lightNavbar
      navbarLogo
      onboardingWizardEnabled
      primaryButtonColor
      primaryColor
    }
  }
}
    `;
export type GQLSetCompanyConfigMutationFn = Apollo.MutationFunction<GQLSetCompanyConfigMutation, GQLSetCompanyConfigMutationVariables>;

/**
 * __useSetCompanyConfigMutation__
 *
 * To run a mutation, you first call `useSetCompanyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompanyConfigMutation, { data, loading, error }] = useSetCompanyConfigMutation({
 *   variables: {
 *      adminTagCreation: // value for 'adminTagCreation'
 *      backgroundColor: // value for 'backgroundColor'
 *      companyLogo: // value for 'companyLogo'
 *      companyLogoTitle: // value for 'companyLogoTitle'
 *      companyLogoUrl: // value for 'companyLogoUrl'
 *      highlightColor: // value for 'highlightColor'
 *      lightBackground: // value for 'lightBackground'
 *      lightNavbar: // value for 'lightNavbar'
 *      navbarLogo: // value for 'navbarLogo'
 *      onboardingWizardEnabled: // value for 'onboardingWizardEnabled'
 *      primaryButtonColor: // value for 'primaryButtonColor'
 *      primaryColor: // value for 'primaryColor'
 *   },
 * });
 */
export function useSetCompanyConfigMutation(baseOptions?: Apollo.MutationHookOptions<GQLSetCompanyConfigMutation, GQLSetCompanyConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLSetCompanyConfigMutation, GQLSetCompanyConfigMutationVariables>(SetCompanyConfigDocument, options);
      }
export type SetCompanyConfigMutationHookResult = ReturnType<typeof useSetCompanyConfigMutation>;
export type SetCompanyConfigMutationResult = Apollo.MutationResult<GQLSetCompanyConfigMutation>;
export type SetCompanyConfigMutationOptions = Apollo.BaseMutationOptions<GQLSetCompanyConfigMutation, GQLSetCompanyConfigMutationVariables>;
export const GetProductsDocument = gql`
    query GetProducts {
  company {
    companyId
    id
    products {
      edges {
        node {
          productId
          name
          companyId
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GQLGetProductsQuery, GQLGetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetProductsQuery, GQLGetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetProductsQuery, GQLGetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetProductsQuery, GQLGetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GQLGetProductsQuery, GQLGetProductsQueryVariables>;
export const GetProductDataDocument = gql`
    query GetProductData {
  company {
    ...ProductCompanyParts
  }
}
    ${ProductCompanyPartsFragmentDoc}`;

/**
 * __useGetProductDataQuery__
 *
 * To run a query within a React component, call `useGetProductDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductDataQuery(baseOptions?: Apollo.QueryHookOptions<GQLGetProductDataQuery, GQLGetProductDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetProductDataQuery, GQLGetProductDataQueryVariables>(GetProductDataDocument, options);
      }
export function useGetProductDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetProductDataQuery, GQLGetProductDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetProductDataQuery, GQLGetProductDataQueryVariables>(GetProductDataDocument, options);
        }
export type GetProductDataQueryHookResult = ReturnType<typeof useGetProductDataQuery>;
export type GetProductDataLazyQueryHookResult = ReturnType<typeof useGetProductDataLazyQuery>;
export type GetProductDataQueryResult = Apollo.QueryResult<GQLGetProductDataQuery, GQLGetProductDataQueryVariables>;
export const GetRoleDataDocument = gql`
    query GetRoleData {
  company {
    ...RoleCompanyParts
  }
}
    ${RoleCompanyPartsFragmentDoc}`;

/**
 * __useGetRoleDataQuery__
 *
 * To run a query within a React component, call `useGetRoleDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleDataQuery(baseOptions?: Apollo.QueryHookOptions<GQLGetRoleDataQuery, GQLGetRoleDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetRoleDataQuery, GQLGetRoleDataQueryVariables>(GetRoleDataDocument, options);
      }
export function useGetRoleDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetRoleDataQuery, GQLGetRoleDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetRoleDataQuery, GQLGetRoleDataQueryVariables>(GetRoleDataDocument, options);
        }
export type GetRoleDataQueryHookResult = ReturnType<typeof useGetRoleDataQuery>;
export type GetRoleDataLazyQueryHookResult = ReturnType<typeof useGetRoleDataLazyQuery>;
export type GetRoleDataQueryResult = Apollo.QueryResult<GQLGetRoleDataQuery, GQLGetRoleDataQueryVariables>;
