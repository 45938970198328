import React from 'react'
import { StarsAndMoonsComponent } from '~/react/common-components/StarsAndMoonsComponent'
import { useTranslation } from 'react-i18next'
import { Employee } from '~/app/types'

/**
 * Contains the stars/moons as well as the reports/descendants counts.
 */
type Props = {
    employee: Employee
}
export const EmployeeBadgeMetricsWithoutDurationOrangesDependency = ({ employee }: Props) => {
    const {
        employment_duration,
        prior_employment_duration,
        prior_company_name,
        is_contractor,
        direct_reports_count: directReportsCount,
        descendants_count: descendantsCount,
    } = employee
    const [t] = useTranslation()
    const hasReports = Boolean(directReportsCount)
    const hasDescendants = hasReports && descendantsCount !== directReportsCount

    return (
        <div className="metrics-container ">
            <StarsAndMoonsComponent
                employmentDuration={employment_duration}
                priorEmploymentDuration={prior_employment_duration}
                priorCompanyName={prior_company_name}
                isContractor={is_contractor}
            />
            {hasDescendants && (
                <div
                    className="node-counts"
                    title={t('profile_page.direct_and_total_reports_count', {
                        directReportsCount,
                        descendantsCount,
                    })}
                >
                    {t('count', { count: directReportsCount })} &middot; {t('count', { count: descendantsCount })}
                </div>
            )}

            {hasReports && !hasDescendants && (
                <div
                    className="node-counts"
                    title={t('profile_page.direct_reports_count', {
                        directReportsCount,
                    })}
                >
                    {t('count', { count: directReportsCount })}
                </div>
            )}
        </div>
    )
}
