import Cookies from 'js-cookie'
import { extractMethodOverride } from '~/utils/ajaxUtils'

$.ajaxSetup({
    crossDomain: false,
    beforeSend: function (xhr, settings) {
        // Workaround to support using a mobile device auth token with ajax get requests.
        // A global variable needs to be defined before the rest of the js on a page loads.
        // If authToken is defined, then that will be used instead of a card token.
        // This will only work for get requests, post/patches will be denied due to needing a CSRF token.
        var authTokenTypeOf = typeof authToken
        if (authTokenTypeOf !== 'undefined' && authToken != null) {
            xhr.setRequestHeader('Authorization', authToken)
        }
        xhr.setRequestHeader('X-CSRFToken', Cookies.get('csrftoken'))

        // This is is needed to support _method input paramaters on forms for PATCHs and DELETEs
        // We pass up a special header that gets handled by MethodOverrideMiddleware.  See comments there
        if (settings.type === 'POST' && settings.data) {
            // Check to see if there is a method override data key
            var overrideMethod = extractMethodOverride(settings)

            if (overrideMethod) {
                // If so, attach it as a header, which our middleware on the server will handle
                xhr.setRequestHeader('X-HTTP-Method-Override', overrideMethod)
            }
        }
    },
})

// If this is a guest authenticated, then we don't want to allow guests to be able to press any button
export function setupGuestNotAllowedClickListener() {
    $('.container-fluid, .canvas-dialog-outer, #two-column-flex')
        .not('.btn-guest-allowed')
        .on('click', '.btn,.block-for-guest', function (e) {
            // TODO this if check is because .not('.btn-guest-allowed') is not working as expected
            // the 'Get Started' button in the 'claim your free trial' banner doesn't work without this
            if ($(this).hasClass('btn-guest-allowed')) {
                return
            }

            if (IS_GUEST) {
                alert(GUEST_ACTION_DENIED_MSG)
                e.preventDefault()
                e.stopImmediatePropagation()
                return false
            }
        })
}

setupGuestNotAllowedClickListener()
