import * as Constants from '~/utils/Constants'
import * as OWUtils from '~/utils/OWUtils'
import { ServerApi } from '~/utils/ServerApi'
import { EmployeeSearchIndex } from '~/models/EmployeeSearchIndex'
import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import * as EmployeeUtils from '~/utils/EmployeeUtils'
import { Employee, Id } from '~/app/types'
import { Global } from '~/global'

export class EmployeesModel {
    static _instance?: EmployeesModel

    private prefetched: boolean
    private readonly employees: { [id: Id]: Employee }
    private readonly freshInserts: { [id: Id]: boolean }
    private readonly searchIndex: EmployeeSearchIndex

    constructor() {
        this.prefetched = false

        // The actual cache map; id(Number) -> employee(Employee)
        this.employees = {}

        // Tracks inserts from this browser session, to avoid flushing fresh data; id(Number) -> true
        this.freshInserts = {}

        this.searchIndex = EmployeeSearchIndex.getInstance()

        // Preload cache with prefetch API
        if (!OWUtils.hasFeature(Constants.Features.DISABLE_PRELOADING)) {
            this.prefetchEmployees()
        }
    }

    static getInstance() {
        if (!this._instance) {
            this._instance = new EmployeesModel()
        }
        return this._instance
    }

    /**
     * Populate the cache by fetching employees from the prefetch endpoint
     */
    prefetchEmployees() {
        if (this.prefetched || !Global.OW_AUTH_DATA.employee?.id) {
            return
        }

        void ServerApi.prefetchEmployees().then(() => (this.prefetched = true))
    }

    /**
     * Insert employees into the cache
     * @param {Employee[]} employees The employees
     * @param {Boolean} [stale] If present, the inserted data is stale
     */
    _insertEmployees(employees, stale = false) {
        const authEmployee = OWAuthDataModel.getAuthEmployee()
        employees.map(e => {
            if (!Global.IS_GHOST && !Global.IS_GUEST && authEmployee && e.lineage) {
                EmployeeUtils.addProximity(authEmployee, [e])
            }

            // Merge on existing
            const existing = this._getEmployee(e.id)
            const merged = Object.assign({}, existing, e)
            this.employees[e.id] = merged
            if (!stale) {
                this.freshInserts[e.id] = true
            }

            if (e.is_active !== false) {
                this.searchIndex.insertEmployee(merged)
            }
            return merged
        })
    }

    /**
     * Insert an employee into the cache
     * @param {Employee} e The employee
     */
    static insertEmployee(e) {
        EmployeesModel.getInstance()._insertEmployees([e])
    }

    /**
     * Get an employee from the cache
     * @param id The id of the employee
     * @returns The employee, or undefined if they are not in the cache
     */
    _getEmployee(id: Id): Employee | undefined {
        return this.employees[id]
    }

    /**
     * Get an employee from the cache
     * @param id The id of the employee
     * @returns The employee, or undefined if they are not in the cache
     */
    static getEmployee(id: Id): Employee | undefined {
        return EmployeesModel.getInstance()._getEmployee(id)
    }

    /**
     * Search the cached employees
     * @param  query The search query
     * @returns  The cached employees that match the query
     */
    searchEmployees(query: string): (Employee | undefined)[] {
        return this.searchIndex.search(query).map(({ id }) => this._getEmployee(id))
    }
}
