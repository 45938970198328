import { TimingUtils } from '~/utils/TimingUtils'

/**
 * This class acts as a global singleton for measuring page load times
 */
export class PageLoadTimeModel {
    static _instance
    private startTime: number

    constructor() {
        const pageNav = PageLoadTimeModel.getPerformanceObj()
        this.startTime = pageNav.fetchStart
    }

    static getPerformanceObj() {
        const [pageNav] = performance.getEntriesByType('navigation')
        return pageNav as PerformanceNavigationTiming
    }

    static getRequestStart() {
        const pageNav = PageLoadTimeModel.getPerformanceObj()
        return pageNav.requestStart
    }

    static getDomComplete() {
        const pageNav = PageLoadTimeModel.getPerformanceObj()
        return pageNav.domComplete
    }

    static getLoadComplete() {
        const pageNav = PageLoadTimeModel.getPerformanceObj()
        return pageNav.loadEventEnd
    }

    /**
     * Gets the time delta (in milliseconds) between the page start load time and a date
     * @param {Number} [time] The time to compare; defaults to now
     * @returns {Number} The number of milliseconds passed, or null if there was no page start load time
     */
    getLoadTime(time = undefined) {
        if (this.startTime) {
            return (time || TimingUtils.now()) - this.startTime
        } else {
            return null
        }
    }

    /**
     * Sets the page start load time
     * @param {Number} [time] The time to set; defaults to now
     */
    setPageLoad(time) {
        this.startTime = time || TimingUtils.now()
    }

    static getInstance() {
        if (!this._instance) {
            this._instance = new PageLoadTimeModel()
        }
        return this._instance
    }
}

PageLoadTimeModel.getInstance()
