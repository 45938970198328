import * as Constants from '~/utils/Constants'
import * as OWUtils from '~/utils/OWUtils'
import { LOCATION_PAGE_TABS, LOCATIONS_PAGE_TABS } from '~/react/tabs'

declare const IS_GUEST
declare const IS_ACTIVE_ADMIN

export const LocationUtils = {
    /**
     * Gets the page title for a location and view flag combination
     */
    getPageTitle({
        name,
        v,
        conferenceRoomSlug,
        conferenceRoomName,
    }: {
        name?: string // The location name
        v?: string //The navigation tab flag
        conferenceRoomSlug?: string //The slug of the conference room
        conferenceRoomName?: string //The name of the conference room
    }) {
        if (name) {
            const tabs = LOCATION_PAGE_TABS
            if (conferenceRoomSlug !== undefined) {
                if (conferenceRoomSlug) {
                    if (v === tabs.edit) {
                        return `${name}: ${conferenceRoomName} Settings | OrgWiki`
                    } else {
                        return `${name}: ${conferenceRoomName} | OrgWiki`
                    }
                } else {
                    if (v === tabs.edit) {
                        return `${name}: New Conference Room | OrgWiki`
                    } else {
                        return `${name}: Conference Rooms | OrgWiki`
                    }
                }
            } else if (v && v === tabs.edit) {
                return `${name}: Settings | OrgWiki`
            } else if (v && v === tabs.history) {
                return `${name}: History | OrgWiki`
            } else if (v && v === tabs.members) {
                return `${name}: People | OrgWiki`
            } else if (v && v === tabs.overview) {
                return `${name}: Overview | OrgWiki`
            } else if (v && v === tabs.seating) {
                return `${name}: Floor Plans | OrgWiki`
            } else {
                return `${name}: Overview | OrgWiki`
            }
        } else {
            const tabs = LOCATIONS_PAGE_TABS
            if (v === tabs.create) {
                return 'New Office | OrgWiki'
            } else if (v === tabs.all) {
                return 'Offices | OrgWiki'
            } else if (v === tabs.inactive) {
                return 'Inactive Offices | OrgWiki'
            } else {
                return 'Offices | OrgWiki'
            }
        }
    },

    canEditConferenceRoom() {
        return !IS_GUEST && (IS_ACTIVE_ADMIN || !OWUtils.hasFeature(Constants.Features.DISABLE_WIKI_OFFICE_EDITS))
    },

    canWikiEditOffice() {
        return !IS_GUEST && (IS_ACTIVE_ADMIN || !OWUtils.hasFeature(Constants.Features.DISABLE_WIKI_OFFICE_EDITS))
    },
}
