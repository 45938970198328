import React from 'react'
import { PlaceManager, urlForOnboarding } from '~/utils/PlaceManager'
import * as OWUtils from '~/utils/OWUtils'
import * as ReactI18next from 'react-i18next'
import { steps } from '~/react/onboarding/Steps'

declare const ONBOARDING_STEP

export const OnboardingBannerComponent = () => {
    const onboardingHref = urlForOnboarding({
        step: ONBOARDING_STEP || steps.welcome,
    })
    const onboardingClick = OWUtils.onFilteredLeftClick(() =>
        PlaceManager.getInstance().goToOnboarding(
            {
                step: ONBOARDING_STEP || steps.welcome,
            },
            true
        )
    )
    return (
        <div className="navbar-banner onboarding-banner">
            <div className="container-fluid">
                <p>
                    <ReactI18next.Trans i18nKey={'banners.welcome_to_orgwiki'}>
                        Welcome to OrgWiki! Please remember to
                        <a href={onboardingHref} onClick={onboardingClick}>
                            complete your profile.
                        </a>
                    </ReactI18next.Trans>
                </p>
            </div>
        </div>
    )
}
