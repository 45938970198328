import React from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { NavbarComponent } from '~/react/navbar/NavbarComponent'
import { FooterComponent } from '~/react/footer/FooterComponent'
import { createRoot, Root } from 'react-dom/client'
import ScrollToTop from '~/app/ScollToTop'
import NotificationRefresher from '~/app/NotificationRefresher'
import MagicLinkComponent from '~/react/mobile/MagicLinkComponent'
import RedirectPage from '~/react/mobile/RedirectPage'
import { App, clientFactory } from '~/app/App'
import { OWApp } from '~/app/OWApp'
import { ApolloProvider } from '@apollo/client'
import { Global } from '~/global'
import { GetBrandingDocument } from '~/generated/graphql'

const OnboardingPageComponent = React.lazy(() => import('~/react/onboarding/OnboardingPageComponent'))
const EmployeeDlistsPageComponent = React.lazy(
    () => import('~/react/employee-profile/dlists/EmployeeDlistsPageComponent')
)
const EmployeeEditPageComponent = React.lazy(() => import('~/react/employee-edit/EmployeeEditPageComponent'))
const EmployeePageComponent = React.lazy(() => import('~/react/employee-profile/EmployeePageComponent'))
const GroupPageComponent = React.lazy(() => import('~/react/groups/GroupPageComponent'))
const GroupsPageComponent = React.lazy(() => import('~/react/groups/GroupsPageComponent'))
const LocationPageComponent = React.lazy(() => import('~/react/locations/LocationPageComponent'))
const LocationsPageComponent = React.lazy(() => import('~/react/locations/LocationsPageComponent'))
const HomePageComponent = React.lazy(() => import('~/react/homepage/HomePageComponent'))
const InMemoriamPageComponent = React.lazy(() => import('~/react/in-memoriam/InMemoriamPageComponent'))
const InMemoriamEditPageComponent = React.lazy(() => import('~/react/in-memoriam/InMemoriamEditPageComponent'))
const LoginPageComponent = React.lazy(() => import('~/react/login/LoginPageComponent'))
const DomainListPageComponent = React.lazy(() => import('~/react/login/DomainListPageComponent'))
const RecoverDomainPageComponent = React.lazy(() => import('~/react/login/RecoverDomainPageComponent'))
const AdminCompanyPageComponent = React.lazy(() => import('~/react/admin/company/AdminCompanyPageComponent'))
const AdminReportsPageComponent = React.lazy(() => import('~/react/admin/reports/AdminReportsPageComponent'))
const AdminFeaturesPageComponent = React.lazy(() => import('~/react/admin/features/AdminFeaturesPageComponent'))
const AdminUsersPageComponent = React.lazy(() => import('~/react/admin/users/AdminUsersPageComponent'))
const MobileNarrativeEditComponent = React.lazy(() => import('~/react/employee-profile/MobileNarrativeEditComponent'))
const ResponsibilitiesEditComponent = React.lazy(() => import('~/react/employee-profile/ResponsibilitiesEditComponent'))
const NewsFeedPageComponent = React.lazy(() => import('~/react/timeline/NewsFeedPageComponent'))
const SingleTLEPageComponent = React.lazy(() => import('~/react/timeline/SingleTLEPageComponent'))
const SearchPageComponent = React.lazy(() => import('~/react/search/SearchPageComponent'))
const MobileManageMembersComponent = React.lazy(() => import('~/react/mobile/MobileManageMembersComponent'))
const ZoomApp = React.lazy(() => import('~/react/zoom_app/ZoomApp'))

export class OWReactPageRouter {
    app: OWApp
    root?: Root

    constructor(app) {
        this.app = app
        const holderDiv = document.querySelector('#ow-react-page')
        if (holderDiv) this.root = createRoot(holderDiv)
    }

    routeCurrentUrl() {
        if (this.root) {
            const fallback = <>...</>
            const split = elem => <React.Suspense fallback={fallback}>{elem}</React.Suspense>
            const employeeRoutes = (
                <>
                    <Route index element={split(<EmployeePageComponent />)} />
                    <Route path=":publicId" element={split(<EmployeePageComponent />)} />
                    <Route path=":publicId/dlists" element={split(<EmployeeDlistsPageComponent />)} />
                    <Route path=":publicId/edit" element={split(<EmployeeEditPageComponent />)} />
                </>
            )
            const client = clientFactory(false)
            client.cache.writeQuery({
                query: GetBrandingDocument,
                data: Global.OW_AUTH_DATA.get_branding?.data,
            })
            this.root.render(
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <ScrollToTop />
                        <NotificationRefresher />
                        <Routes>
                            <Route path="/" element={<App />}>
                                <Route path="mobile">
                                    <Route
                                        path="responsibilities/:publicId"
                                        element={split(
                                            <MobileNarrativeEditComponent narrativeType="responsibilities" />
                                        )}
                                    />
                                    <Route
                                        path="about/:publicId"
                                        element={split(<MobileNarrativeEditComponent narrativeType="about" />)}
                                    />
                                    <Route
                                        path={'manage_members/:slug'}
                                        element={split(<MobileManageMembersComponent />)}
                                    />
                                </Route>
                                <Route path="recover" element={split(<RecoverDomainPageComponent />)} />
                                <Route path="login" element={split(<LoginPageComponent />)} />
                                <Route path="zoom_app" element={split(<ZoomApp />)} />
                                <Route path="domain_list" element={split(<DomainListPageComponent />)} />
                                ;
                                <Route path="my-profile/:step" element={split(<OnboardingPageComponent />)} />
                                <Route element={<WithNavBar />}>
                                    <Route path="e">{employeeRoutes}</Route>
                                    <Route path="employees">{employeeRoutes}</Route>
                                    <Route path="groups">
                                        <Route index element={split(<GroupsPageComponent />)} />
                                        <Route path=":slug" element={split(<GroupPageComponent />)} />
                                    </Route>
                                    <Route path="offices">
                                        <Route index element={split(<LocationsPageComponent />)} />
                                        <Route path=":slug" element={split(<LocationPageComponent />)} />
                                        <Route
                                            path=":slug/conference_rooms"
                                            element={split(<LocationPageComponent isConferenceRoom={true} />)}
                                        />
                                        <Route
                                            path=":slug/conference_rooms/:conferenceSlug"
                                            element={split(<LocationPageComponent isConferenceRoom={true} />)}
                                        />
                                    </Route>
                                    <Route path="social">
                                        <Route index element={split(<SingleTLEPageComponent />)} />
                                        <Route path=":tleId" element={split(<SingleTLEPageComponent />)} />
                                    </Route>
                                    <Route path="responsibilities">
                                        <Route index element={split(<ResponsibilitiesEditComponent />)} />
                                        <Route path=":publicId" element={split(<ResponsibilitiesEditComponent />)} />
                                    </Route>

                                    <Route path="search" element={split(<SearchPageComponent />)} />
                                    <Route path="feed">
                                        <Route index element={split(<NewsFeedPageComponent />)} />
                                        <Route path={':tabName'} element={split(<NewsFeedPageComponent />)} />
                                    </Route>
                                    <Route path="admin">
                                        <Route index element={split(<AdminUsersPageComponent />)} />
                                        <Route path="users" element={split(<AdminUsersPageComponent />)} />
                                        <Route path="features" element={split(<AdminFeaturesPageComponent />)} />
                                        <Route path="reports" element={split(<AdminReportsPageComponent />)} />
                                        <Route path="company" element={split(<AdminCompanyPageComponent />)} />
                                    </Route>
                                    <Route path="in-memoriam/:publicId">
                                        <Route index element={split(<InMemoriamPageComponent />)} />
                                        <Route path="edit" element={split(<InMemoriamEditPageComponent />)} />
                                    </Route>
                                    <Route index element={split(<HomePageComponent />)} />
                                    <Route path="*" element={<Navigate to="/" replace />} />
                                    <Route path={'mobile'}>
                                        <Route index element={split(<RedirectPage />)} />
                                        <Route path={'magic/:token'} element={split(<MagicLinkComponent />)} />
                                    </Route>
                                </Route>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ApolloProvider>
            )
        }
    }
}

const WithNavBar = () => (
    <>
        <NavbarComponent />
        <div className={'ow-react-page page-content'}>
            <Outlet />
        </div>
        <FooterComponent />
    </>
)
