import Cookies from 'js-cookie'
import ReactGA from 'react-ga'
import { PageLoadTimeModel } from '~/models/PageLoadTimeModel'
import { DependencyList, useEffect } from 'react'

declare const COMPANY_ID

type LogOptions = {
    label?: string
    publicKey?: string
}
/**
 * Provides support for logging timing events to Google Analytics
 */
let _docLoaded = false

export const TimingNames = {
    EmployeeProfilePage: 'employee-profile',
    FeedPage: 'feed-page',
    HomePage: 'home-page',
    AdvancedSearch: 'advanced-search',
    CommunityOverview: 'communities-profile-overview',
    CommunityMembers: 'communities-profile-members',
    CommunityPosts: 'communities-profile-posts',
    CommunitiesList: 'communities-list',
    CommunitiesPendingRequests: 'communities-pending-requests',
    DListMembers: 'dlists-profile-members',
    DListsList: 'dlists-list',
    DListsPendingRequests: 'dlists-pending-requests',
    LocationsList: 'locations-list',
    LocationMembers: 'location-members',
}

export const TimingCategories = {
    DocLoaded: 'doc-loaded', // Time to load root document
    DocReady: 'doc-ready', // Time to Parse JS (DOM READY)
    DocUsable: 'doc-usable', // Time to load main document content
    DocExtra: 'doc-extra', // Time to load extra document content
}

export const Dimensions = {
    CompanyId: 'dimension1',
    PublicKey: 'dimension2',
}

export const debugCookie = 'debug_perf'

/**
 * Logs a timing event to Google Analytics
 * @param {string} category The timing event category
 * @param {string} name The timing event name
 * @param {Number} [delta] The timing (in ms)
 * @param {string} [label] An optional label for the event
 * @param {string} [publicKey] An optional public key for the event
 */

export const logTimingEvent = (category: string, name: string, delta: number, { label, publicKey }: LogOptions) => {
    const hit = {
        hitType: 'timing',
        timingCategory: category,
        timingVar: name,
        timingValue: delta,
        ...(label && { timingLabel: label.toString() }),
        [TimingUtils.Dimensions.CompanyId]: COMPANY_ID.toString(),
        ...(publicKey && {
            [TimingUtils.Dimensions.PublicKey]: publicKey.toString(),
        }),
    }
    ReactGA.ga('send', hit)
    if (Cookies.get(TimingUtils.DebugCookie)) {
        console.log(hit)
    }
}

/**
 * Gets the current posix time
 * @returns {Number} The current time
 */
export const now = () => Date.now()

/**
 * Mark the document as loaded
 */
export const docLoaded = () => {
    _docLoaded = true
}

/**
 * Check if the document has loaded
 */
export const hasDocLoaded = (): boolean => _docLoaded

export const TimingUtils = {
    TimingCategories,
    Dimensions,
    TimingNames,
    DebugCookie: debugCookie,
    logTimingEvent,
    now,
    docLoaded,
    hasDocLoaded,
}

export const useTiming = (name: string, options: LogOptions, deps: DependencyList) => {
    useEffect(() => {
        if (!hasDocLoaded() && name) {
            const { publicKey, label } = options
            const instance = PageLoadTimeModel.getInstance()
            const extra = { ...(publicKey && { publicKey }), ...(label && { label }) }
            logTimingEvent(
                TimingCategories.DocLoaded,
                name,
                instance.getLoadTime(PageLoadTimeModel.getLoadComplete()),
                extra
            )
            logTimingEvent(
                TimingCategories.DocReady,
                name,
                instance.getLoadTime(PageLoadTimeModel.getDomComplete()),
                extra
            )
            docLoaded()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, options, ...deps])
}
