/**
 * Updates the global style sheets with new values for branding colors
 * Keep this aligned with "branding.css.html"
 * @param {String} primary_color
 * @param {String} background_color
 * @param {String} highlight_color
 * @param {String} primary_button_color
 */

// CSS variable added for brand colors
export function updateBrandingStyles({ primary_color, background_color, highlight_color, primary_button_color }) {
    const cssColorMap = {
        '--primary_color': primary_color,
        '--background_color': background_color,
        '--highlight_color': highlight_color,
        '--primary_button_color': primary_button_color,
        '.ow-b-bgc-primary': primary_color,
        '.ow-b-bgc-highlight': highlight_color,
        '.skillLevelMeter > div': highlight_color,
        '.ow-b-bgc-background': background_color,
        '.btn-primary, .selected-item a.skillPill:hover': primary_button_color,
        '.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:active:hover, .btn-primary:active:focus, .selected-item:hover, .selected-item:focus, .selected-item:active, .selected-item:active:hover, .selected-item:active:focus, .dropdown-toggle.btn-primary:hover, .dropdown-toggle.btn-primary:focus, .dropdown-toggle.btn-primary:active, .open > .dropdown-toggle.btn-primary, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:active, .open > .dropdown-toggle.btn-primary:active:hover, .open > .dropdown-toggle.btn-primary:active:focus, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus':
            primary_button_color,
        '.ow-b-c-primary': primary_color,
        '.ow-b-c-highlight': highlight_color,
        '.ow-b-c-background': background_color,
        '.ow-b-c-button': primary_button_color,
        '.ow-b-boc-primary': primary_color,
        '.ow-b-bocf-primary:focus': primary_color,
        '.ow-b-boc-highlight': highlight_color,
        '.ow-b-brand-button': primary_button_color,
        '.ow-b-brand-border-bottom': primary_color,
        'li.ow-b-bgc-pagination a, .filterPill.positive': primary_button_color,
        '.managerBrowseContainer .managerRowContainer.selected > .managerRow .details label': primary_button_color,
    }

    // Branding stylesheet is always last, and has no href
    const styleSheets = Array.from<CSSStyleSheet>(document.styleSheets)
    const styleSheet = styleSheets.find(s => !s.href)
    if (styleSheet) {
        const rules = Array.from(styleSheet.cssRules)
        rules.forEach(rule => {
            const cssStyleRule = rule as CSSStyleRule
            if (cssStyleRule) {
                const toSet = cssColorMap[cssStyleRule.selectorText]
                const style = cssStyleRule.style

                if (toSet) {
                    // Construct list of style properties to update
                    const props: any[] = []
                    for (let i = 0; style.item(i); i++) {
                        props.push(style.item(i))
                    }
                    props.forEach(key => style.setProperty(key, toSet, style.getPropertyPriority(key)))
                }
            }
        })
    }
}
