import { Bloodhound } from 'bloodhound-js'
import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import * as EmployeeUtils from '~/utils/EmployeeUtils'

declare const IS_GHOST
declare const IS_GUEST
declare const API_URL_EMPLOYEES_LIST
declare const OW_AUTH_DATA

let employeeDataSourceInstance

function addOrgDistancesForUser(r) {
    const authEmployee = OWAuthDataModel.getAuthEmployee()
    if (!IS_GHOST && !IS_GUEST && authEmployee && r.lineage) {
        return EmployeeUtils.addProximity(authEmployee, r)
    } else return r
}

export const TypeaheadUtils = {
    sanitizeLocalStorageKey(key) {
        return key.replace('.', '_').replace(' ', '')
    },

    employeeDataSource() {
        if (!employeeDataSourceInstance) {
            employeeDataSourceInstance = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace(
                    'nick_name',
                    'last_name',
                    'first_name',
                    'full_name'
                ),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                identify: e => e.public_id,
                prefetch: {
                    url: API_URL_EMPLOYEES_LIST + '?page_size=0',
                    ttl: 600000,
                    transform: addOrgDistancesForUser,
                },
                remote: {
                    url: API_URL_EMPLOYEES_LIST + '?page_size=12&search=%QUERY',
                    wildcard: '%QUERY',
                    transform: function (results) {
                        addOrgDistancesForUser(results)
                        return results.sort(EmployeeUtils.employeeSorter)
                    },
                },
                sorter: EmployeeUtils.employeeSorter,
            })
            employeeDataSourceInstance.initialize()
        }
        return employeeDataSourceInstance
    },

    makeLocalDataSource(suggestion, header, notFound, data, pred, dtok, qtok) {
        if (!pred) pred = () => true
        const src = new Bloodhound({
            datumTokenizer: dtok ?? Bloodhound.tokenizers.obj.whitespace('name'),
            queryTokenizer: qtok ?? Bloodhound.tokenizers.whitespace,
            identify: e => e.name,
            local: data,
        })
        return {
            display: 'name',
            limit: 12,
            templates: { suggestion, header, notFound },
            source: (query, sync) => src.search(query, res => sync(res.filter(pred))),
        }
    },

    makeTypeahead(element, options, datasets) {
        const typeahead = element.typeahead(options, datasets)
        typeahead.on('typeahead:render', () => {
            $('.tt-suggestion').hover(ev => {
                $('.tt-suggestion').removeClass('tt-cursor')
                $(ev.target).addClass('tt-cursor')
            })
        })
        return typeahead
    },

    /**
     * Add a value as the most recent instant search result
     * @param {Object} obj The object to add
     * @param {string} type The type of the object
     * @param {function} keyFunc A function that gets a unique id for objects of that type
     */
    addToInstantSearch(obj, type, keyFunc) {
        const existingResults = (OW_AUTH_DATA.instant_search || {}).record_set || []
        const idx = existingResults.findIndex(res => res.type === type && keyFunc(res.value) === keyFunc(obj))
        if (idx !== -1) {
            existingResults.splice(idx, 1)
        }
        existingResults.unshift({ value: obj, type })
    },
}
