import { MutableRefObject, useEffect, useState } from 'react'

type UseOutsideAlerterReturnType = {
    showElement: boolean
    setShowElement: (visible: boolean | ((old: boolean) => boolean)) => void
}

export const useOutsideAlerter = (
    initialIsVisible: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: MutableRefObject<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buttonRef?: MutableRefObject<any>
): UseOutsideAlerterReturnType => {
    const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible)

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target) && !buttonRef?.current?.contains(event.target)) {
                setIsVisible(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, buttonRef])

    return { showElement: isVisible, setShowElement: setIsVisible }
}
