import { mixpanelTrack } from '~/utils/MixpanelUtils'
import { isMobileDevice } from '~/utils/mobileDetection'

declare const IS_AUTHENTICATED
declare const addToHomescreen

/**
 * Checks to see if we should prompt the user with the "Add to Home Screen" prompt, and
 * does it if necessary.
 * Current contraints:
 *  - iOS only (for now)
 *  - iPhone only, no iPads (for now)
 *  - User must be logged in
 *  - On home page only
 *  - Device must be in Portrait orientation
 *
 * To test / show this easily, add the following parameters to the addToHomescreen method:
 *  - maxDisplayCount: 0
 *  - displayPace: 0
 *  - lifespan: 0
 *  - debug: true
 */
export function promptMobileBookmarkIfNecessary() {
    const isAndroid = navigator.userAgent.toLowerCase().includes('android')

    // Since we have native app support for ios and android, we wont be showing bookmark anymore.
    if (isMobileDevice.iOS() || isAndroid) {
        return
    }

    // No Tablets (iPads) for now
    if (addToHomescreen.isTablet) {
        return
    }

    // Must be authenticated
    if (!IS_AUTHENTICATED) {
        return
    }

    // Only do this on the home page
    if (location.pathname !== '/') {
        return
    }

    // Only do this if the orientation is Portrait.  Dont bother for landscape
    // NOTE: this only works on real devices (or simulator), doesnt work in device mode in chrome
    if (!(window.orientation === 0 || window.orientation === 180)) {
        return
    }

    // Use the addtohomescreen.js third party library to accomplish this
    addToHomescreen({
        skipFirstVisit: true, // Dont show on the first visit to homescreen, show on second
        maxDisplayCount: 1, // Only show once ever (tracked with local storage)
        lifespan: 0, // Prompt doesnt go away by itself, must be dismissed by user
        startDelay: 0.5, // Show the prompt after 0.5 seconds after page load
        icon: true,
        message: 'To install OrgWiki, tap %icon and choose "Add to Home Screen"',
        onShow: function () {
            // Track via Mixpanel that we showed this prompt
            void mixpanelTrack('Add To Home Screen Prompt displayed')
        },
    })
}
